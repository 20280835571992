import { createContext, useContext } from 'react';

interface AuthContextProps {
  hasValidToken: boolean;
  login: (username: string, password: string) => void;
  logout: () => void;
  loading: boolean;
  error: Error | null;
}

export const AuthContext = createContext<AuthContextProps>({
  hasValidToken: false,
  login: () => null,
  logout: () => null,
  loading: true,
  error: null
});

export const useAuthContext = () => useContext(AuthContext);
