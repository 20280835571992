import React, {useState, useEffect} from "react";
import axios from "axios";

import {useAuthContext} from "../util/AuthContext";
import AppContainer from "../components/AppContainer";
import Table from "../components/Table";


const SimResults: React.FC = () => {
    const [fetched, setFetched] = useState<boolean>(false);
    const [data, setData] = useState<JSX.Element[][]>([]);
    const { hasValidToken } = useAuthContext();

    const getSimResults = () => {
        if (hasValidToken && !fetched){
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/sims/results`)
            .then((resp) => {
                let rows = []
                for (var i in resp['data']['data']){
                    let val = resp['data']['data'][i];
                    let href = <a href={`/fills?fillPath=${val}`}>{val}</a>
                    rows.push([href]);
                }
                setData(rows);
                setFetched(true);
            })
        }
    }

    useEffect(() => getSimResults(), [hasValidToken, fetched]);

    return (
      <AppContainer bgColor={'#FFF8F0'}>
          <h1> Sim results </h1>
          { fetched && (<Table columns={['result']} rows={data} columnFilters={[]}/>)}
          { !fetched && (<div>loading...</div>)}
      </AppContainer>
    );
};

export default SimResults;