import React from "react";
import {useState} from "react";

import DepositMenu from "../depositMenu";
import {useNavigate} from "react-router-dom";

const CoinbaseAdvancedMenu: React.FC = () => {
    const [displayDepositMenu, setDisplayDepositMenu] = useState<boolean>(false);
    const navigate = useNavigate();

    return (
      <div>
        { displayDepositMenu && <DepositMenu closeMenu={() => setDisplayDepositMenu(false)} />}
          <button onClick={() => setDisplayDepositMenu(true)}>
              Deposit USD
          </button>
          <button onClick={() => navigate("/orders/all")}>
              All Orders
          </button>
      </div>
    );
};

export default CoinbaseAdvancedMenu;