import React, {useState} from "react";
import "../App.css";
import EditNoteIcon from '@mui/icons-material/EditNote';
import {getCsrfTokenCookie} from "../util/Misc";

import {
    Dialog,
    Container,
    DialogActions,
    DialogTitle,
    DialogContent,
    Button,
    TextField,
    Typography
} from "@mui/material"
import axios from "axios";

interface EditBotProps {
    botId: string,
    botName: string,
    margin: string,
    subscription: string,
    symbols: string,
    active: boolean
}

const EditBot: React.FC<EditBotProps> = ({
                                             botId,
                                             botName,
                                             margin,
                                             subscription,
                                             symbols,
                                             active
}) => {
    const [show, setShow] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>(active);
    const [editMade, setEditMade] = useState<boolean>(false);
    const [newMargin, setNewMargin] = useState(margin);

    const handleMarginUpdate = (e:any) => {
        console.log("update")
        setEditMade(margin !== e.target.value as string);
        setNewMargin(e.target.value as string);
    }

    const handleSubmit = (e:any) => {
        e.preventDefault()
        console.log(newMargin + " for bot " + botId)
        let csrfCookie = getCsrfTokenCookie();

        axios.put(`${process.env.REACT_APP_BASE_API_URL}/api/bot`, {
            botId: botId,
            margin: newMargin
        }, {
            withCredentials: true,
            headers: {
                "X-CSRFToken": csrfCookie
            }
        }).then((resp) => {
            window.location.reload()
        })
    }

    return (
        <Container>
            <EditNoteIcon
                style={{float: "right"}}
                onClick={() => setShow(true)}
            />
            <Container>
                <Dialog open={show}>
                    <DialogTitle>Info for {botName}</DialogTitle>
                    <DialogContent>
                        <div>
                            <Typography>ID: {botId}</Typography>
                        </div>
                        <form>
                            <Typography>Subscription: {subscription}</Typography>
                            <Typography>Symbol(s): {symbols}</Typography>
                            <TextField
                                label="Margin"
                                value={newMargin}
                                margin="dense"
                                type="text"
                                onChange={handleMarginUpdate}
                            />
                            <div>
                                Active
                                <input
                                    style={{height: "25px", width: "25px"}}
                                    type={"checkbox"}
                                    checked={isActive}
                                    onChange={() => setIsActive(!isActive)}
                                />
                            </div>
                             <DialogActions>
                              <Button onClick={() => setShow(false)}>
                                Cancel
                              </Button>
                              <Button
                                  onClick={handleSubmit}
                                  disabled={!editMade} type="submit" color="primary">
                                Save
                              </Button>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </Dialog>
            </Container>
        </Container>
    );
};

export default EditBot;
