import React, {FormEvent, useEffect, useState} from "react";

import axios from 'axios';

import Dropdown from "./Dropdown";
import {useAuthContext} from "../util/AuthContext";

interface SimPlannerProps {
    models: string[],
    strats: string[]
}

const SimPlanner: React.FC<SimPlannerProps> = ({models, strats}: SimPlannerProps) => {
    const [name, setName] = useState<string>('');
    const [strat, setStrat] = useState<string>(strats[0]);
    const [model, setModel] = useState<string>(models[0]);
    const [responseMessage, setResponseMessage] = useState<string>('');
    const { hasValidToken } = useAuthContext();

    const [symbol, setSymbol] = useState<string>('BTC');
    const [startDt, setStartDt] = useState<string>('2022-01-01');
    const [endDt, setEndDt] = useState<string>('2022-01-02');
    const [eventTypes, setEventTypes] = useState<string[]>([]);
    const [eventType, setEventType] = useState<string>('');
    const [dts, setDts] = useState<string[]>([]);

    const [artifactId, setArtifactId] = useState<string>('-1')

    const selectEvent = (e:FormEvent) => {
        let val = (e.target as HTMLInputElement).value;

        axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/datasets/dataset?name=${val}`)
            .then((resp) => {
                let dts = resp['data']['existing_dates'];
                setDts(dts);
                setStartDt(dts[0][0]);
                setEndDt(dts[0][0]);
                setEventType(val);
            });
    }

    const fetchEventTypes = () => {
        if (hasValidToken){
             axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/datasets`)
            .then((resp) => {
                const datasetNames: string[] = JSON.parse(resp.data.datasets).map((ds: any) => ds.fields.name)
                setEventTypes(datasetNames);
            })
        }
    }

    const handleArtifactId = (e:FormEvent) => {
        let inVal = (e.target as HTMLInputElement).value;
        setArtifactId(inVal);
    }

    const validatePlan = ( e:FormEvent ) => {
        if (!name){
            alert('name must be provided')
        } else {
            let params = {
                'name': name,
                'strat': strat,
                'model': model,
                'artifactId': artifactId,
                'symbol': symbol,
                'startDt': startDt,
                'endDt': endDt,
                'eventType': eventType
            }
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/sims/run`, {
                params: params
            })
            .then((resp) => {
                setResponseMessage('success');
            })
        }
    }

    useEffect(() => fetchEventTypes(), [hasValidToken])

    return (
        <div>
            <h3>Run Simluation</h3>

            <Dropdown
                label={'Event Type'}
                options={eventTypes}
                handleChange={selectEvent}
            />

            <input
                placeholder={'sim name'}
                onChange={(e:FormEvent) => (setName((e.target as HTMLInputElement).value))}
            />
            <Dropdown
              label={'model'}
              options={models}
              handleChange={(e) => setModel((e.target as HTMLInputElement).value)}
            />
            <input placeholder={'artifact_id'} onChange={handleArtifactId}/>
            <Dropdown
                label={'strategy'}
                options={strats}
                handleChange={(e) => setStrat((e.target as HTMLInputElement).value)}
            />
            <Dropdown
                label={'symbol'}
                options={['BTC', 'ETH']}
                handleChange={(e) => setSymbol((e.target as HTMLInputElement).value)}
            />
            <Dropdown
                label={'start_dt'}
                options={dts}
                handleChange={(e) => setStartDt((e.target as HTMLInputElement).value)}
            />
            <Dropdown
                label={'end_dt'}
                options={dts}
                handleChange={(e) => setEndDt((e.target as HTMLInputElement).value)}
            />
            <button onClick={validatePlan}>Validate</button>
            <h3>{ responseMessage }</h3>
        </div>

    );
};

export default SimPlanner;