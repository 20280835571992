import React from "react";

import AppContainer from "../components/AppContainer";
import ModelEvalSubmit from "../components/modelEvalSubmit";


const ModelEval: React.FC = () => {

    return (
        <AppContainer bgColor={'#FFF8F0'}>
            <ModelEvalSubmit />
        </AppContainer>
    );
};

export default ModelEval;