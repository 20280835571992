import React, {useEffect, useState} from 'react';

import {useAuthContext} from "../util/AuthContext";
import AppContainer from '../components/AppContainer';
import axios from "axios";
import Table from "../components/Table";

const Home: React.FC = () => {
    const productColumns = [
        'Name',
        'Price',
        'Change 24h',
        'Volume 24h',
        'volume % change 24h',
        'base increment',
        'quote increment',
        'quote min size',
        'quote max size',
        'base min size',
        'base max size',
        'base name',
        'quote name',
        'watched',
        'disabled',
        'new',
        'status'
    ]

    const {hasValidToken} = useAuthContext();
    const [products, setProducts] = useState<null|(string|JSX.Element)[][]>(null);

    const getProducts = () => {
        if (hasValidToken && !products){
            console.log('fetch')
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/coinbaseadvanced/products`)
            .then((resp) => {
                let respProducts: (string|JSX.Element)[][] = [];
                for (let item of Object.values(resp.data.products)){
                    let itemObj = item as Map<string, string>
                    let itemArr = Object.values(itemObj) as string[]|JSX.Element[];
                    const baseName = String(itemArr[11]).toLowerCase().replace(/ /g, "-")
                    itemArr[0] = <a href={`https://coinbase.com/price/${baseName}`} target={"_blank"}>{itemArr[0]}</a>
                    if (Number(itemArr[2]) > 0){
                        itemArr[2] = <div style={{color: 'green'}}>{Number(itemArr[2]).toFixed(2)}</div>
                    } else {
                        itemArr[2] = <div style={{color: 'red'}}>{Number(itemArr[2]).toFixed(2)}</div>
                    }
                    respProducts.push(itemArr);
                }
                setProducts(respProducts);
            })
        }
    }

    useEffect(() => {
        getProducts()
    }, [hasValidToken])

    return (
      <AppContainer bgColor={'#2787EA'}>
          { products &&
              <div>
                  <Table columns={productColumns} rows={Object.values(products)} columnFilters={['Name', 'quote name']} />
              </div>
          }
      </AppContainer>
    );
};

export default Home;