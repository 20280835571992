import axios from 'axios'

const getSubscriptions = () => {
        return axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/subscriptions`)
        .then((resp) => {
            if (resp.status === 200){
                return resp.data
            }
        }).then((data) => {
            return data.subscriptions
        })
}

export {
    getSubscriptions
};