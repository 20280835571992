import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Table from "../components/Table";
import BotCreator from "../components/BotCreator";
import { useAuthContext } from '../util/AuthContext';
import AppContainer from "../components/AppContainer";
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {CircularProgress} from "@mui/material";
const Bots: React.FC = () => {
  const [bots, setBots] = useState<(number|string)[][]|null>(null);
  const [fetched, setFetched] = useState<boolean>(false);
  const [totalSpent, setTotalSpent] = useState<number>(0);
  const [totalOpen, setTotalOpen] = useState<number>(0);
  const { hasValidToken, error } = useAuthContext();
  const [serviceHealthy, setServiceHealth] = useState<boolean|null>(null);

  const getBots = () => {
      if (hasValidToken){
        if (!fetched){
          axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/bots`)
          .then((resp) => {
              setFetched(true);
              let totalSpent: number = 0
              let totalOpen: number = 0
              let data: any[][] = [];
            for (var bot in resp.data['bots']){
                let row = resp.data['bots'][bot]
                let percent: number = Number(row[9]) / Number(row[2]) * 100.0
                let color = percent < 100 ? 'red' : 'green'
                row[0] = <div><a href={`/bot/${row[0]}`}>{row[1].toString()}</a></div>
                row[1] = <div style={{"color": color}}>{percent.toFixed(2)}%</div>
                row[4] = Number(row[4]).toFixed(2)
                const tmpSymAmount = row[3]
                row[3] = row[5]
                row[5] = tmpSymAmount
                data.push(row)
                totalSpent += Number(row[2])
                if (Number(row[9])){
                    totalOpen += Number(row[9])
                }
            }
            setBots(data);
              console.log('total spent: ' + totalSpent)
              console.log('total open: ' + totalOpen)
              setTotalOpen(totalOpen)
              setTotalSpent(totalSpent)
          })
        }
      }
  }

  const checkBotService = () => {
      if (hasValidToken){
          axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/bot/service/healthy`)
              .then((resp)=> {
                  return resp.data
              }).then((data) => {
                  setServiceHealth(data.healthy)
              })
      }
  }

  useEffect(() => {
      getBots()
      checkBotService()
  }, [hasValidToken, bots]);

  const serviceFetched: boolean = serviceHealthy !== null;

  return (
    <AppContainer bgColor={'#FFF8F0'}>
        <h3>bot interface</h3>
        <div>Bot Service Status
            {serviceFetched && (serviceHealthy ?
                <CheckCircleIcon style={{color: "green"}}></CheckCircleIcon> : <ErrorIcon style={{color:"red"}}></ErrorIcon>
            )}
            {!serviceFetched && <CircularProgress size={20}/>}

        </div>
        { totalOpen !== 0 && <h4>Open: ${totalOpen.toFixed(2)}</h4> }
        { totalSpent !== 0 && <h4>Spent: ${totalSpent.toFixed(2)}</h4> }
        { (totalSpent !== 0 && totalOpen !== 0) && <h4>Profit/Loss: ${(totalOpen - totalSpent).toFixed(2)}
            ({((((totalOpen - totalSpent)/totalSpent) *100.0).toFixed(2))} %) </h4>}
        <BotCreator/>
        {bots &&
        <Table
          columns={['Bot', 'G/L', 'Open', 'Symbol', 'Margin', 'amount', 'Exchange',
                    'Strategy', 'Price', 'Value', 'Bot Id']}
          rows={bots}
          columnFilters={[]}
        />}
        {!bots && <CircularProgress />}
        {error && <div>error</div>}
    </AppContainer>
  );

};

export default Bots;