import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { useAuthContext } from "../util/AuthContext";

import AppContainer from '../components/AppContainer';
import Table from '../components/Table';


const Orders: React.FC = () => {
    const [columns, setColumns] = useState<string[]>([]);
    const [orders, setOrders] = useState<string[][]>([]);
    const { hasValidToken, loading } = useAuthContext();

    const loadingScreen = (
        <h1>Loading Your Orders :)</h1>
    )

    function get_orders() {
        if (hasValidToken){
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/cnbs/cnbs_tx_hist`)
            .then((response) => {
                return response['data']
            })
            .then((data) => {
                setOrders(data["data"])
                setColumns(['symbol', 'dt', 'amount', 'native_amount', 'price', 'tx_type', 'cb_src']);
            })
            .catch((err) => console.log('fetching orders error' + err));
        }

    }

    useEffect(() => {
        get_orders();
    }, [hasValidToken, loading, columns]);


    return (
      <AppContainer bgColor={'#FFF8F0'}>
        <h1 style={{ textAlign: 'center' }}>Orders</h1>

          {loading && loadingScreen}
          {!loading &&
              <Table columns={columns}
                     rows={orders}
                     columnFilters={['symbol']}
              />
          }
      </AppContainer>
    );
};

export default Orders;