import React from 'react';

import styles from './coinsummary.module.css'

interface CoinSummaryProps {
    name: string,
    bid: number,
    day_change: number,
    week_change: number,
    month_change: number
}

const CoinSummary: React.FC<CoinSummaryProps> = (props: CoinSummaryProps) => {
    const day_change_pct: number = (props.bid - props.day_change) / props.day_change * 100.0
    const week_change_pct: number = (props.bid - props.week_change) / props.week_change * 100.0
    const month_change_pct: number = (props.bid - props.month_change) / props.month_change * 100.0

    let pct_change_style = styles.element_container;
    let week_change_pct_style = styles.element_container;
    let month_change_pct_style = styles.element_container;

    if (day_change_pct < 0){
        pct_change_style = styles.pct_change_container_down;
    } else if (day_change_pct > 0){
        pct_change_style = styles.pct_change_container_up;
    } else {
        pct_change_style = styles.pct_change_container_up;
    }

    if (week_change_pct < 0){
        week_change_pct_style = styles.pct_change_container_down;
    } else {
        week_change_pct_style = styles.pct_change_container_up;
    }

    if (month_change_pct < 0){
        month_change_pct_style = styles.pct_change_container_down;
    } else {
        month_change_pct_style = styles.pct_change_container_up;
    }


    return (
      <div className={styles.summary_container}>
        <div className={styles.ticker_container}>{props.name}</div>
        <div className={styles.element_container}>{Number(props.bid).toFixed(2)}</div>
        <div className={styles.element_container}>{props.day_change.toFixed(2)}</div>
        <div className={pct_change_style}>{day_change_pct.toFixed(2)} %</div>
        <div className={styles.element_container}>{Number(props.week_change).toFixed(2)}</div>
        <div className={week_change_pct_style}>{week_change_pct.toFixed(2)} %</div>
        <div className={styles.element_container}>{Number(props.month_change).toFixed(2)}</div>
        <div className={month_change_pct_style}>{month_change_pct.toFixed(2)} %</div>
      </div>
    );
}

export default CoinSummary;