import React from 'react';
import AppContainer from "../../components/AppContainer";
import Dropdown from "../../components/Dropdown";

const SimExplorer: React.FC = () => {
    return (
        <AppContainer bgColor={'#2787EA'}>
            <Dropdown label={'Datset'}
                      options={[]}
                      handleChange={() => console.log('')} />

            Buy When: <input/>
            Sell When: <input />
        </AppContainer>
    )
};

export default SimExplorer;