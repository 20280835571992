import React, {useEffect, useState} from "react";
import AppContainer from "../components/AppContainer";
import {useAuthContext} from "../util/AuthContext";
import axios from "axios";

const EthFoundationRss: React.FC = () => {
    const [rssData, setRssData] = useState<any>(null);
    const {hasValidToken} = useAuthContext();

    const fetchRss = () => {
        if (hasValidToken) {
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/rss`)
                .then((resp) => {
                    return resp.data
                }).then((data) => {
                    setRssData(data.feed);
            })
        }
    }

    useEffect(() => fetchRss(), [hasValidToken])

    return (
        <AppContainer bgColor={"grey"}>
            <div>{rssData && rssData.map((rssEntry: any) => (
                <div>
                    <a href={`${rssEntry.link}`}>{`${rssEntry.title}`}</a>
                    <text>{new Date(rssEntry.published).toLocaleString('en-us',
                        {year: 'numeric', month: '2-digit', day: '2-digit'})}
                    </text>
                </div>
            ))}
            </div>
        </AppContainer>
    );
};

export default EthFoundationRss;