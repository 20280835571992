import React from 'react';

import NavBar from './NavBar';
import Footer from './Footer';
import Login from '../../components/Login';
import {useAuthContext} from '../../util/AuthContext'
import { Container } from '@mui/material';

import styles from './appcontainer.module.css'

interface AppContainerProps {
    bgColor: string,
    children: React.ReactNode
}

const AppContainer: React.FC<AppContainerProps> = (props: AppContainerProps) => {
    const {hasValidToken, loading, error} = useAuthContext();

    if (loading) {
        return (
            <div className={styles.app_container_div} style={{background: props.bgColor}}>
                <Container maxWidth="xs">
                <div style={{ background: 'orange' }}></div>
                </Container>
          </div>
        );
    }

    if (hasValidToken) {
        return (
            <Container sx={{'m': 0}} maxWidth={false} style={{background: props.bgColor}} disableGutters>
                <NavBar bodyBg={'#2787EA'} overlayBg={'white'} hasValidAuthToken={true}/>
                {error && <div style={{background: 'red'}}>ERROR: {error.toString()}</div>}
                <Container maxWidth="xl" disableGutters>
                    <div className={styles.content_container}>
                        {props.children}
                    </div>
                </Container>
                <Footer bodyBg={'#2787EA'}/>
            </Container>
        );
    }

    return (
        <div className={styles.app_container_div} style={{background: props.bgColor}}>
            <Login/>
            <Footer bodyBg={'#2787EA'}/>
        </div>
    );
};

export default AppContainer;