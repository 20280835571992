import React,
{
  ReactNode, useState, useCallback, useEffect
} from 'react';
import {getCsrfTokenCookie} from "../util/Misc";
import axios from 'axios';
import { useQueryClient } from 'react-query';

import { AuthContext } from './AuthContext';
import { get, set, clearAuth, checkExpire } from './LocalStorage';

interface AuthProps {
  children: ReactNode;
}

const Auth: React.FC<AuthProps> = ({children}) => {
  const [hasValidToken, setHasValidToken] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const queryClient = useQueryClient();

  const clearData = useCallback(() => {
    setHasValidToken(false);
    clearAuth();
    queryClient.clear();
    axios.defaults.headers.common['Authorization'] = false;
  }, [queryClient])

  const logout = () => {
    clearData();
  }

  const login = async (username:string, password:string) => {
    setError(null);
    setLoading(true);
    let csrfCookie = getCsrfTokenCookie();
    if (csrfCookie === 'null'){
        const resp = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/csrf`, {withCredentials: true})
        csrfCookie = getCsrfTokenCookie();
    }
    try {
      const auth = `Basic ${btoa(`${username}:${password}`)}`;
      const loginUrl = `${process.env.REACT_APP_BASE_API_URL}/api/login/`;
      await axios.post(loginUrl, {}, {
          withCredentials: true,
          headers: {
            "Authorization": auth,
            "X-CSRFToken": csrfCookie,
          }
      });
      set('token', auth)
      axios.defaults.headers.common['Authorization'] = auth;
      setHasValidToken(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e as Error);
    }

  };

  // intercept resp and logout if unauthorized
  axios.interceptors.response.use(
    (res) => {
      setError(null);
      return res
    },
    (err) => {
      // todo: this should definitely
      //      set errors in AuthContext
      //      then we can use that value in AppContainer
      console.log('axios error')
      console.log(err)
      console.log(err.response.status)
      if (err && err.response && err.response.status === 401){
        logout();
      }
      setError(err);
      return Promise.reject(err);
    },
  );
  axios.defaults.headers.common.Accept = 'application/json';

  useEffect(() => {
    const token = get('token');
    const isExpired = checkExpire('token')
    if (token && !isExpired){
      axios.defaults.headers.common['Authorization'] = token;
      setHasValidToken(true);
    } else if (token){
      clearData();
      setError(new Error('Token is invalid, please reauthenticate'));
    } else {
      setHasValidToken(false);
    }
    setLoading(false);
  }, [clearData]);

  return (
  <AuthContext.Provider
    value={{
      hasValidToken,
      login,
      logout,
      loading,
      error
    }}
  >
  {children}
  </AuthContext.Provider>
  );
};

export default Auth;