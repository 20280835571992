import React, { useEffect, useState } from 'react';
import axios from 'axios';

import AppContainer from "../components/AppContainer";
import Table from "../components/Table";
import { useAuthContext } from "../util/AuthContext";

const ModelArtifacts: React.FC = () => {
    const { loading } = useAuthContext();
    const [data, setData] = useState<any[]>([]);
    const [fetched, setFetched] = useState<boolean>(false);

    useEffect(() => {
        if (!loading && !fetched){
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/models/artifacts`)
            .then((resp)=> {
                let rows = [];
                for (let k in resp['data']['res']){
                    rows.push(resp['data']['res'][k])
                }
                setData(rows);
                setFetched(true);
            })
        }
    }, [loading, data]);

    if (loading){
        return (
            <AppContainer bgColor={'#FFF8F0'}>
                <h3>loadin...</h3>
            </AppContainer>
        );
    }


    return (
        <AppContainer bgColor={'#FFF8F0'}>
            <h1>Model Artifacts</h1>
            <Table
              columns={['id', 'model_class', 'features', 'response', 'model_path', 'meta']}
              rows={data}
              columnFilters={[]}
            />
        </AppContainer>
    );
};

export default ModelArtifacts;
