import React from "react";
import * as echarts from "echarts";

const TestEcharts: React.FC = () => {
    return (
        <div>
            <h2>Echarts</h2>
        </div>
    );
};

export default TestEcharts;