import React, { useState, FormEvent } from 'react';
import axios from 'axios';
import Dropdown from '../components/Dropdown';


interface TrainingSchedulerProps{
    dataset: number,
    features: string[],
    existing_dates: string[],
    modelTypes: string[]
}

const TrainingScheduler: React.FC<TrainingSchedulerProps> = ({ dataset,
                                                             features,
                                                             existing_dates,
                                                             modelTypes }: TrainingSchedulerProps) => {
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);
  const [errorMsg, setError] = useState<string|null>(null);
  const [selectedModel, setSelectedModel] = useState<string>('');
  const [response, setResponse] = useState<string>('');
  const [symbol, setSymbol] = useState<string>('BTC');
  const [startDt, setStartDt] = useState<string>('');
  const [endDt, setEndDt] = useState<string>('');
  const [showTrainingPlan, setShowTrainingPlan] = useState<boolean>(false);
  const [planStatus, setPlanStatus] = useState<string>('');
  const [epochs, setEpochs] = useState<string>('1');
  const [meta, setMeta] = useState<string>('{}');

  function reactStatusBanner(){
      if (!planStatus){
          return
      }
      return (<h3>Status: {planStatus}</h3>);
  }

  const selectResponse = (e:FormEvent) => {
    setResponse((e.target as HTMLInputElement).value);
  }

  const updateEndDt = (e:FormEvent) => {
    setEndDt((e.target as HTMLInputElement).value);
  }

  const updateStartDt = (e:FormEvent) => {
    setStartDt((e.target as HTMLInputElement).value);
  }

  const checkFeature = (e:FormEvent) => {
    const target: HTMLInputElement = (e.target as HTMLInputElement);
    const feat: string = target.value;
    const checked = target.checked;
    if (checked){
      selectedFeatures.push(feat);
    } else {
      selectedFeatures.splice(selectedFeatures.indexOf(feat));
    }
  }

  const makeTrainingPlan = (e:FormEvent) => {
      let valid = true;
      let errs = '';

      if (meta){
        try {
          JSON.parse(meta);
        } catch (e){
          alert('meta must be valid JSON');
          valid = false;
          errs += 'Please provide a valid meta'
       }
      }


      if (!selectedModel){
          setSelectedModel(modelTypes[0]);
      }

      if (!response){
          valid = false;
          errs += 'Please select a valid response '
      }

      if (selectedFeatures.length < 1){
          valid = false;
          errs += 'Please select at least one training feature '
      }

      if (!startDt){
          if (existing_dates.length > 0){
              setStartDt(existing_dates[0][0])
          }
      }

      if (!endDt){
          if (existing_dates.length > 0){
              setEndDt(existing_dates[0][0])
          }
      }

      if (!valid){
          setError(errs)
      } else {
          setError(null);
          setShowTrainingPlan(true);
      }
  }

  const updateEpochs = (e:FormEvent) => {
      setEpochs((e.target as HTMLInputElement).value);
  }

  const updateMeta = (e:FormEvent) => {
    setMeta((e.target as HTMLInputElement).value);
  }

  const submitTrainingPlan = (e:FormEvent) => {
      let params = {
          datasetId: dataset,
          model: selectedModel,
          symbol: symbol,
          response: response,
          features: selectedFeatures.toString(),
          startDt: startDt,
          endDt: endDt,
          epochs: epochs,
          meta: meta
      };
      setPlanStatus('submitted');
      axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/models/training/submit`, {
          params: params
      }).then((resp) => {
          setPlanStatus('success');
      }).catch((err) => {
          setPlanStatus('error!' + err);
      })
  }

  return (
    <div>
    {!showTrainingPlan  && (<div>
      <h3>TrainingScheduler</h3>
        { errorMsg && (<h1>ERROR! {errorMsg} </h1>)}
        <Dropdown
        label={'model type'}
        options={modelTypes}
        handleChange={(e:FormEvent) => setSelectedModel((e.target as HTMLInputElement).value)}
      />
        <div>
            <input placeholder={symbol}
            onChange={(e:FormEvent) => setSymbol((e.target as HTMLInputElement).value)}/>
            <label>Symbol</label>
        </div>

      <Dropdown
        label={'response'}
        options={['-'].concat(features)}
        handleChange={selectResponse}
      />
      <h3>features</h3>
      <ul>
      {
        features.map((feat) => (
          <li style={{ textDecoration: 'none' }}>
            {feat}
            <input value={feat} type='checkbox' onClick={checkFeature}/>
          </li>
        ))
      }
      </ul>
      <h3>dts ({existing_dates.length}): </h3>
      <Dropdown
        label={'Start Dt'}
        options={existing_dates}
        handleChange={updateStartDt}
      />
      <Dropdown
        label={'End Dt'}
        options={existing_dates}
        handleChange={updateEndDt}
      />
        <div>
            <input placeholder={'epochs'} onChange={updateEpochs}/>
        </div>
        <div>
            <input placeholder={'meta'} defaultValue={"{}"} onChange={updateMeta}/>
        </div>
      <button onClick={makeTrainingPlan}>Create Training Plan</button>
    </div>)
    }
    { showTrainingPlan  && (
        <div>
            <h3>Training Plan</h3>
            <div> dataset id: {dataset} </div>
            <div> Symbol: {symbol} </div>
            <div> response {response}</div>
            <div> feats: {selectedFeatures.map((feat) => (<span>{feat}</span>))}</div>
            <div> start: {startDt}, end: {endDt} </div>
            <div> model: {selectedModel} </div>
            <div> epochs: {epochs} </div>
            <div> meta: {meta} </div>
            <button onClick={submitTrainingPlan}>submit</button>
            <button onClick={(e: FormEvent) => (setShowTrainingPlan(false))}>cancel</button>
            { reactStatusBanner() }
        </div>
        )}
    </div>);
};


export default TrainingScheduler;