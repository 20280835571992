import React, {FormEvent, useState, useEffect} from 'react';
import axios from 'axios';

import AppContainer from '../components/AppContainer';
import Dropdown from '../components/Dropdown';
import TrainingScheduler from '../components/TrainingScheduler';

import {useAuthContext} from '../util/AuthContext';

const Models: React.FC = () => {
    const [trainProps, setTrainProps] = useState({'features': [], 'existing_dates': [], 'dataset_id': -1});
    const {hasValidToken} = useAuthContext();
    const [fetched, setFetched] = useState<boolean>(false);
    const [datasets, setDatasets] = useState<string[]>([]);
    const [modelTypes, setModelTypes] = useState<string[]>([]);

    const fetchModelTypes = () => {
        if (hasValidToken && !fetched){
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/models/training/supported`)
            .then((resp) => {
                setFetched(true);
                setModelTypes(resp['data']['data']);
            })
        }
    }

    const fetchDatasets = () => {
        if (hasValidToken){
            console.log('fetch datasets!')
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/datasets`)
                .then((resp) => {
                    const datasetNames: string[] = JSON.parse(resp.data.datasets).map((ds: any) => ds.fields.name)
                    setDatasets(datasetNames);
                    if (datasetNames.length > 0){
                        queryDatasetWrapper(datasetNames[0]);
                    }
                })
        }
    }

    const queryDatasetWrapper = (dataset: string) => {
        axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/datasets/dataset?name=${dataset}`)
        .then((resp) => {
            setTrainProps(resp['data']);
        })
    }

    const queryDataset = (e: FormEvent) => {
        const dataset = (e.currentTarget as HTMLInputElement).value;
        queryDatasetWrapper(dataset);
    }


    const modelView = (
        <div>
            <h1>Model Training</h1>
            <Dropdown
                label={'Dataset'}
                options={datasets}
                handleChange={queryDataset}
            />
            <TrainingScheduler
                dataset={trainProps.dataset_id}
                features={trainProps.features}
                existing_dates={trainProps.existing_dates}
                modelTypes={modelTypes}
            />
        </div>
    );

    const loadingView = (<h2>Loading</h2>);

    useEffect(() => {
        fetchModelTypes()
        fetchDatasets()
    }, [hasValidToken, modelTypes])

    return (
        <AppContainer bgColor={'#FFF8F0'}>
            {fetched && modelView}
            {!fetched && loadingView}
        </AppContainer>
    );
};

export default Models;
