import React, {useEffect, useState} from "react";
import axios from 'axios';
import AppContainer from "../components/AppContainer";
import {useAuthContext} from "../util/AuthContext";
import Table from "../components/Table";
import {DatePicker} from "@mui/x-date-pickers"
import dayjs, {Dayjs} from "dayjs";

const OpportunityReport: React.FC = () => {
    const yest = new Date()
    const {hasValidToken} = useAuthContext();
    yest.setDate(yest.getDate() - 1)
    const [tradeDt, setTradeDt] = useState<string>(yest.toLocaleString('en-us', {year: 'numeric', month: '2-digit', day: '2-digit'}))
    const [rowData, setRowData] = useState<string[][]>([])


    const fetchResults = (dt: string) => {
        if (hasValidToken){
            console.log("FETCH RES")
            console.log(dt)
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/sims/opportunity`, {
                 params: {run_dt: dt}
            })
                .then((resp)=> {
                    console.log(resp)
                        setRowData(resp.data.data.map((r:any) => {
                        const l = r.order_json.orders.length
                        return [
                            r.response,
                            r.name,
                            r.config,
                            `${l}`,
                            r.order_json['total_earnings'].toPrecision(3),
                            `$${r.order_json['starting_price'].toFixed(2)}`,
                            `$${r.order_json['ending_price'].toFixed(2)}`,
                            `${r.order_json['pct_change'].toFixed(2)}%`,
                        ]
                    }))
                }
            )
        }
    }
    const handleChangeDt = (dt:any) => {
        if (dt) {
            setTradeDt(dt.format("MM/DD/YYYY"));
            fetchResults(dt.format("MM/DD/YYYY"));
        }
    }
    useEffect(()=>fetchResults(tradeDt), [hasValidToken, tradeDt])

    return (
            <AppContainer bgColor={"#2787EA"}>
            <h1>Opportunity Report - {tradeDt}</h1>
            <DatePicker
                value={dayjs(tradeDt)}
                onChange={(e) => handleChangeDt(e)}
            />
            <Table columns={['Response', 'Sim Class', 'Config', 'Num Orders', 'Sim Earnings', 'Starting Price', 'Ending Price', '% change']}
                   rows={rowData}
                   columnFilters={[]}/>
        </AppContainer>
    );
};

export default OpportunityReport;