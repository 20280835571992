import React from 'react';
import axios from 'axios';
import {CircularProgress} from "@mui/material";
import AppContainer from "../components/AppContainer";
import Button from "@mui/material/Button";
import {useState} from "react";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ReactMarkdown from 'react-markdown';

const Test: React.FC = () => {
    const [input, setInput] = useState<string>('');
    const [loadingOutput, setLoadingOutput] = useState<boolean>(false);
    const [output, setOutput] = useState<string|null>(null);
  const handleSubmit = (e:any) => {
      console.log(input)
      setLoadingOutput(true);
      e.preventDefault();
      console.log("Need to hit backend")
      axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/codingbot`,
          {
              params: {'input': input}
          }).then((resp) => {
            setOutput(resp.data.result)
          })
  }

  return (
  <AppContainer bgColor={"#FFF8F0"}>
      {output &&
          <Button variant="contained" color="primary" onClick={() => setOutput(null)}>
              Restart
          </Button>}
      {!loadingOutput &&
        <Container maxWidth="sm">
          <Typography variant="h4" align="center" gutterBottom>
            Please ask for whatever python code you want from the AI development team
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Enter text"
              variant="outlined"
              margin="normal"
              onChange={(e) => setInput(e.target.value)}
            />
          </form>
        </Container>}
      {(loadingOutput && !output) &&
        <Container maxWidth="sm">
            <div>
              <Typography variant="h4" align="center" gutterBottom>
              Hitting OpenAI
              </Typography>
            </div>
          <div style={{ display: "flex", justifyContent: "center"}}>
            <CircularProgress />
          </div>
        </Container>
      }
      {output &&
          <div className="codeOutput">
            <ReactMarkdown>
              {output}
            </ReactMarkdown>
          </div>
      }
  </AppContainer>
  );
}

export default Test;
