import React, {useEffect} from "react";
import * as d3 from "d3";
import {BrushSelection, D3BrushEvent} from "d3";

interface ScatterPlotProps {
    data: {
        [key: string]: [number, number][]
    },
    line?: [number, number][]
}

const Scatter: React.FC<ScatterPlotProps> = ({data, line}: ScatterPlotProps) => {
    const totalHeight = 600;
    const totalWidth = 800;
    let xMax = -1, yMax = -1, xMin = 9999999999999999, yMin = 9999999999999999;
    let newYMin = xMin;

    const scaleAxis = () => {
        for (let k in data){
            let vals = data[k];
            let localMax = Math.max(...vals.map((d) => d[0]));
            xMax = localMax > xMax ? localMax : xMax;
            let localMin = Math.min(...vals.map((d) => d[0]));
            xMin = localMin < xMin ? localMin : xMin;

            localMax = Math.max(...vals.map((d) => d[1]));
            yMax = localMax > yMax ? localMax : yMax;
            localMin = Math.min(...vals.map((d) => d[1]));
            yMin = localMin < yMin ? localMin : yMin;
        }

        if (line){
            let localMax = Math.max(...line.map((d) => d[0]));
            xMax = localMax > xMax ? localMax : xMax
            let localMin = Math.min(...line.map((d) => d[0]));
            xMin = localMin < xMin ? localMin : xMin

            localMax = Math.max(...line.map((d) => d[1]));
            yMax = localMax > yMax ? localMax : yMax;
            localMin = Math.min(...line.map((d) => d[1]));
            yMin = localMin < yMin ? localMin : yMin;
        }
    }
    scaleAxis()

    const margin = { top: 10, right: 20, bottom: 20, left: 50 }
    let colors = ['green', 'red', 'blue', 'green', 'orange'];

    const createPlot = (margin: any) => {
        let width = totalWidth - margin.left - margin.right;
        let height = totalHeight - margin.top - margin.bottom;
        let defaultSelection: BrushSelection = [[0, height*.75], [width, height]];


        d3.select('svg').remove()

        let svg = d3.select('#scatterDiv')
            .append('svg')
            .attr('width', width + margin.right + margin.left)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);


        let xMinDt = new Date(xMin);
        let xMaxDt = new Date(xMax);
        let xRange = [0, width];
        let xDomain = [xMinDt, xMaxDt];
        let yDomain = [yMin, yMax];

        const VIEW_RATIO = .75

        let viewHeight = height*VIEW_RATIO;
        let brushHeight = height*(1-VIEW_RATIO);

        let xScale = d3.scaleTime()
            .domain(xDomain)
            .range(xRange);

        let xScaleBrush = d3.scaleTime()
            .domain([xMinDt, xMaxDt])
            .range(xRange);

        svg.append('g')
            .attr('transform', `translate(0,${viewHeight})`)
            .attr('class', 'xAxisPlot')
            .call(d3.axisBottom(xScale));

        svg.append('g')
            .attr('transform', `translate(0,${height})`)
            .call(d3.axisBottom(xScaleBrush));

        let yScale = d3.scaleLinear()
            .domain(yDomain)
            .range([viewHeight, 0]);

        let yScaleBrush = d3.scaleLinear()
            .domain(yDomain)
            .range([brushHeight, 0]);

        svg.append('g')
            .attr('transform',  `translate(0,0)`)
            .attr('class', 'yAxisPlot')
            .call(d3.axisLeft(yScale));

        svg.append('g')
            .attr('transform',  `translate(0,${viewHeight})`)
            .call(d3.axisLeft(yScaleBrush));

        const renderView = () => {
            let c = 0;
            for (let k in data){
                let vals: [number, number][] = [];
                for (let x in data[k]){
                    //console.log(x)
                    //console.log(data[k][x])
                    //console.log(data[k][x][1], 'newYmin', newYMin, 'ymax: ', yMax)
                    if (data[k][x][1] > newYMin){
                        //console.log(data[k][x][1], yMin, 'less than!', 'ymax: ', yMax)
                        vals.push(data[k][x]);
                    }
                }
                svg.append('g')
                    .selectAll('dot')
                    .data(vals)
                    .join('circle')
                    .attr('class', 'scatterPoint')
                    .attr('cx', d => xScale(d[0]))
                    .attr('cy', d => yScale(d[1]))
                    .attr('r', 6)
                    .attr('stroke', `${colors[c % colors.length]}`)
                    .attr("stroke-width", 6)
                    .attr("fill", "white")

                svg.append('g')
                    .selectAll('dot')
                    .data(data[k])
                    .join('circle')
                    .attr('class', 'scatterPoint')
                    .attr('cx', d => xScaleBrush(d[0]))
                    .attr('cy', d => yScaleBrush(d[1]) + .75*height)
                    .attr('r', 6)
                    .attr('stroke', `${colors[c++ % colors.length]}`)
                    .attr("stroke-width", 6)
                    .attr("fill", "white")
            }

            if (line){
                let newLine: [number, number][] = [];

                for (let v in line){
                    if (line[v][1] >= newYMin){
                        newLine.push(line[v]);
                    }
                }

                svg.append('path')
                    .datum(newLine)
                    .attr('fill', 'none')
                    .attr('class', 'pathLine')
                    .attr('stroke', 'blue')
                    .attr('stroke-width', 1)
                    .attr('d', d3.line()
                        .x((d) => xScale(d[0]))
                        .y((d) => yScale(d[1]))
                    )

                svg.append('path')
                    .datum(line)
                    .attr('fill', 'none')
                    .attr('stroke', 'blue')
                    .attr('stroke-width', 1)
                    .attr('d', d3.line()
                        .x((d) => xScaleBrush(d[0]))
                        .y((d) => yScaleBrush(d[1])+.75*height)
                    )
            }
        }

        const brushing = (selection: D3BrushEvent<any>) => {
            //console.log('brushing')
            //console.log(yDomain)
            //console.log(yMax);
        }

        const brushed = (selection: D3BrushEvent<any>) => {
            if (selection.selection) {
                // console.log(selection.selection)
                let y1 = (selection.selection[0] as [number, number])[1]
                let y2 = (selection.selection[1] as [number, number])[1]
                let x1 = (selection.selection[0] as [number, number])[0]
                let x2 = (selection.selection[1] as [number, number])[0]

                let range = yMax - yMin
                let newYMax = range - (y1 - viewHeight) / brushHeight * range + yMin
                newYMin = range - (y2 - viewHeight) / brushHeight * range + yMin

                range = xMax - xMin;
                let newXMin = xMin + x1 / width * range;
                let newXMax = xMin + x2 / width * range;
                let xNewMinDt = new Date(newXMin);
                let xNewMaxDt = new Date(newXMax);
                xDomain = [xNewMinDt, xNewMaxDt];
                //console.log(viewHeight)
                //console.log(height)
                //y1 = (y1 / height ) * yMin
                yDomain = [newYMin, newYMax]
                yScale = d3.scaleLinear()
                    .domain(yDomain)
                    .range([viewHeight, 0]);

                xScale = d3.scaleTime()
                .domain(xDomain)
                .range(xRange);

                d3.selectAll('.scatterPoint').remove()
                d3.selectAll('.pathLine').remove()
                d3.selectAll('.yAxisPlot').remove()
                d3.selectAll('.xAxisPlot').remove()

                svg.append('g')
                .attr('transform', `translate(0,0)`)
                .attr('class', 'yAxisPlot')
                .call(d3.axisLeft(yScale));

                svg.append('g')
                .attr('transform', `translate(0,${viewHeight})`)
                .attr('class', 'xAxisPlot')
                .call(d3.axisBottom(xScale));

                //scaleAxis();
                renderView();
            }
        }

        const brushArea = d3.brush<any>()
                .extent([[0, height*.75], [width, height]])
                .on('brush', brushing)
                .on('end', brushed);
        svg.call(brushArea).call(brushArea.move, defaultSelection);
        renderView()

    }

    useEffect(() => createPlot(margin), []);

    return (
      <div id={'scatterDiv'}></div>
    );
}

export default Scatter;