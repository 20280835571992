import React, {useEffect, useState} from "react";
import {getSubscriptions} from "../util/API";
import axios from "axios";
import AppContainer from "../components/AppContainer";
import {useAuthContext} from "../util/AuthContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
const Feeds: React.FC = () => {
    const {hasValidToken} = useAuthContext();
    const [subscriptions, setSubscriptions] = useState<any>();
    const [serviceHealth, setServiceHealth] = useState<null|boolean>(null);
    const getSubs = () => {
        if (hasValidToken){
            getSubscriptions().then((subs:any) => setSubscriptions(subs))
        }
    }

    const checkMdFeed = () => {
        if (hasValidToken){
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/subscriptions/health`)
                .then((resp) => {
                    return resp.data
                }).then((data) => {
                    setServiceHealth(data.healthy);
                })
                .catch((err) => setServiceHealth(false))
        }
    }

    useEffect(() => {
        getSubs();
        checkMdFeed();
    }, [hasValidToken])

  return (
      <AppContainer bgColor={'white'}>
          <h2>Service status
              {serviceHealth !== null && (serviceHealth ?
                <CheckCircleIcon style={{color: "green"}}></CheckCircleIcon> : <ErrorIcon style={{color:"red"}}></ErrorIcon>
              )}
          </h2>
          <h2>{subscriptions && subscriptions.map((sub:string) => <div>{sub}</div>)}</h2>
      </AppContainer>
  );
};

export default Feeds;