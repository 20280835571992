import React, {useState, useEffect} from "react";
import axios from "axios";

import {useAuthContext} from "../util/AuthContext";

import AppContainer from "../components/AppContainer";
import SimPlanner from "../components/SimPlanner";


const Sims: React.FC = () => {
    const { loading } = useAuthContext();
    const [ models, setModels ] = useState<string[]>([]);
    const [ strats, setStrats ] = useState<string[]>([]);

    const getStratInfo = () => {
        if (!loading){
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/strats/supported`)
            .then((resp)=> {
                setModels(resp.data.models);
                setStrats(resp.data.strats);
            })
        }
    }

    useEffect(() => getStratInfo(), [loading]);

    return (
        <AppContainer bgColor={'#FFF8F0'}>
            {!loading && <SimPlanner models={models} strats={strats} />}
        </AppContainer>
    );
};

export default Sims;