import React, {useEffect} from 'react';
import * as d3 from "d3";
import {brush, BrushSelection, D3BrushEvent} from "d3";

interface LinePlotProps {
    data: {
        [key: string]: number[]
    }
}

const LinePlot: React.FC<LinePlotProps> = ({data}) => {
    const colors = ['black', 'blue', 'green', 'orange'];

    const totalHeight = 600;
    const totalWidth = 1300;

    const margin = { top: 10, right: 20, bottom: 20, left: 50 },
            width = totalWidth - margin.left - margin.right,
            height = totalHeight - margin.top - margin.bottom;

    const VIEW_RATIO = 0.75
    const viewHeight = height*VIEW_RATIO
    const brushHeight = height*(1-VIEW_RATIO)

    let defaultSelection: BrushSelection = [[0, height*.75], [width, height]];

    let xMax = -1;
    let xMin = 0;
    let yMax = -1;
    let yMin = 99999999;

    const scaleMinMax = () => {
        for (var k in data) {
            let vals = data[k];
            if (vals.length > xMax) {
                xMax = vals.length + 1;
            }
            let seriesMax = Math.max(...vals);
            if (seriesMax > yMax) {
                yMax = seriesMax;
            }

            let seriesMin = Math.min(...vals);
            if (seriesMin < yMin) {
                yMin = seriesMin;
            }
        }
    }

    scaleMinMax()
    let newYMin = yMin;
    let newYMax = yMax;
    let newXMin = xMin;
    let newXMax = xMax;


    const createPlot = () => {
        d3.select('.pathLine').remove()

        let svg = d3.select('#plotDiv')
                    .append('svg')
                    .attr('width', width + margin.right + margin.left)
                    .attr('height', height + margin.top + margin.bottom)
                    .append('g')
                    .attr('transform', `translate(${margin.left},${margin.top})`);


        let xScale = d3.scaleLinear()
            .domain([newXMin, newXMax])
            .range([0, width]);

        let xScalePreview = d3.scaleLinear()
            .domain([xMin, xMax])
            .range([0, width]);

        let yScale = d3.scaleLinear()
            .domain([newYMin, newYMax])
            .range([viewHeight, 0]);

        svg.append('g')
            .attr('transform', `translate(0,${viewHeight})`)
            .call(d3.axisBottom(xScale));
        svg.append('g').call(d3.axisLeft(yScale));

        let yScalePreview = d3.scaleLinear()
            .domain([yMin, yMax])
            .range([height, viewHeight]);
        svg.append('g').call(d3.axisLeft(yScalePreview));


        svg.append('g')
            .attr('transform', `translate(0,${height})`)
            .call(d3.axisBottom(xScalePreview));

        const renderLines = (preview: boolean) => {
            let c = 0;
            for (let k in data){
                let inData: [number, number][] = [];
                let filtData: [number, number][] = [];
                let inVals = data[k];
                for (const i in inVals){
                    const index: number = parseInt(i);
                    if (index >= newXMin && index <= newXMax){
                        if (inVals[index] >= newYMin && inVals[index] <= newYMax)
                            filtData.push([index, inVals[index]]);
                    }
                    inData.push([index, inVals[index]]);
                }

                svg.append('path')
                .datum(filtData)
                .attr('class', 'pathLine')
                .attr('fill', 'none')
                .attr('stroke', colors[c])
                .attr('stroke-width', 1)
                .attr('d', d3.line()
                    .x((d)=> xScale(d[0]))
                    .y((d)=> yScale(d[1]))
                )

                if (preview){
                    svg.append('path')
                    .datum(inData)
                    .attr('fill', 'none')
                    .attr('stroke', colors[c++])
                    .attr('stroke-width', 1)
                    .attr('d', d3.line()
                        .x((d)=> xScalePreview(d[0]))
                        .y((d)=> yScalePreview(d[1]))
                    )
                }

            }
        }

        renderLines(true);

        const brushed = (selection: D3BrushEvent<any>) => {
            console.log(selection)
            if (selection.selection){
                let y1 = (selection.selection[0] as [number, number])[1]
                let y2 = (selection.selection[1] as [number, number])[1]
                let x1 = (selection.selection[0] as [number, number])[0]
                let x2 = (selection.selection[1] as [number, number])[0]

                let range = xMax - xMin;
                newXMin = xMin + x1 / width * range;
                newXMax = xMin + x2 / width * range;

                range = yMax - yMin
                newYMax = range - (y1 - viewHeight) / brushHeight * range + yMin
                newYMin = range - (y2 - viewHeight) / brushHeight * range + yMin
                console.log(newXMax, newXMin)
                d3.select('svg').remove()
                createPlot()
            }
        }

        const brushArea = d3.brush<any>()
            .extent([[0, height*.75], [width, height]])
            .on('end', brushed);
        svg.call(brushArea)//.call(brushArea.move, defaultSelection);
    }

    useEffect(() => createPlot(), [])

    return (
      <div id={'plotDiv'}></div>
    );
};

export default LinePlot;