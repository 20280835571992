export function set(key: string, value: string): Record<string, unknown> {
  localStorage[key] = value;
  if (key === 'token'){
    const date = new Date();
    localStorage[`${key}-expiry`] = new Date(date.getTime() + 86400000);
  }

  return localStorage[key];

}

export function get(key: string, defaultValue=undefined): string {
  const val = localStorage[key] || defaultValue;
  return val;
}

export function clear(): void {
  return localStorage.clear()
}

export function remove(key:string): void {
  return localStorage.removeItem(key);
}

export function checkExpire(key: string): boolean {
  const valExpiry = get(`${key}-expiry`);
  const val = get(key);
  if (!valExpiry || !val) return true;
  return new Date() > new Date(valExpiry);
}

export function clearAuth(): void {
  localStorage.removeItem('token');
  localStorage.removeItem('token-expiry')
}
