import React, {useState, FormEvent, useEffect} from "react";
import axios from "axios";

import Dropdown from "./Dropdown";
import {useAuthContext} from "../util/AuthContext";

const ModelEvalSubmit: React.FC = () => {
    const { loading } = useAuthContext();
    const [eventType, setEventType] = useState<string>('minute');
    const [eventTypes, setEventTypes] = useState<string[]>(['minute']);
    const [artifactId, setArtifactId] = useState<string>('');
    const [symbol, setSymbol] = useState<string>('');
    const [startDt, setStartDt] = useState<string>('');
    const [endDt, setEndDt] = useState<string>('');
    const [dts, setDts] = useState<string[]>([]);
    const [stdScaler, setStdScaler] = useState<boolean>(true);
    const [respMessage, setRespMessage] = useState<string>('');

    const fetchEventTypes = () => {
        if (!loading){
             axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/datasets`)
            .then((resp) => {
                const datasetNames: string[] = JSON.parse(resp.data.datasets).map((ds: any) => ds.fields.name)
                setEventTypes(datasetNames);
            })
        }
    }

    const selectEvent = (e: FormEvent) => {
        let val:string = (e.target as HTMLInputElement).value;
        setEventType(val);
        axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/datasets/dataset?name=${val}`)
            .then((resp)=>{
                return resp['data']
            }).then((data) => {
                setDts(data.existing_dates);
        })
    }

    const showEvalSubmission = (e: FormEvent) => {
        if (!symbol) {
            alert('need symbol bruv');
            return
        }

        if (!artifactId) {
            alert('need artifact id bruv');
        }

        axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/models/evaluation/submit`, {params: {
                modelArtifactId: artifactId,
                symbol: symbol,
                startDt: startDt,
                endDt: endDt,
                stdScaler: stdScaler,
                eventType: eventType
            }
        })
        .then((resp)=>{
            return resp['data']
        }).then((data) => {
            console.log(data);
            setRespMessage(data['message']);
        })
    }

    const main = (
        <div>
            <Dropdown
                label={'event'}
                options={eventTypes}
                handleChange={selectEvent}
            />
            <div>
                <input placeholder={'Artifact ID'}
                       onChange={(e: FormEvent) => setArtifactId((e.target as HTMLInputElement).value)}
                />
            </div>
            <div>
                <input placeholder={'symbol(s)'}
                       onChange={(e: FormEvent) => setSymbol((e.target as HTMLInputElement).value)}
                />
            </div>
            <label>use Standard Scaler</label>
            <input type={'checkbox'}
                   checked={stdScaler}
                   onChange={(e) => setStdScaler((e.target as HTMLInputElement).checked)}
            />
            <Dropdown
                label={'start date'}
                options={dts}
                handleChange={(e:FormEvent) => setStartDt((e.target as HTMLInputElement).value)}
            />
            <Dropdown
                label={'end date'}
                options={dts}
                handleChange={(e:FormEvent) => setEndDt((e.target as HTMLInputElement).value)}
            />
            <button onClick={showEvalSubmission}>Submit</button>
            { respMessage && <div>{respMessage}</div> }
        </div>);

    useEffect(() => fetchEventTypes(), [loading]);

    return (
        <div>
            <h1>Model Eval</h1>
            {!loading && main}

        </div>
    );
};

export default ModelEvalSubmit;