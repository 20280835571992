import React, {useState} from 'react';
import AppContainer from "../components/AppContainer";
import {useParams} from "react-router-dom";
import {useAuthContext} from "../util/AuthContext";
import axios from "axios";
import {useEffect} from "react";
import Table from "../components/Table";
import EditBot from "../components/EditBot";
import {Grid, Container, Button} from '@mui/material';

const Bot: React.FC = () => {
    const {botId} = useParams();
    const {loading} = useAuthContext();
    const [botInfo, setBotInfo] = useState<string[] | null>(null);
    const [orders, setOrders] = useState<string[][]>([]);
    const [openAmount, setOpenAmount] = useState<null | number>(null);
    const [symAmountOpen, setSymOpen] = useState<null | number>(null);
    const [histPrices, setHistPrices] = useState<number[][]>([]);
    const [botNickname, setBotNickname] = useState<string|null>(null);

    const fetchBot = () => {
        if (!loading) {
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/bot/${botId}`)
                .then((resp) => {
                    let info = resp.data.bot_detail;
                    let botOrders = info[8] as string[][];
                    setOrders(botOrders);
                    info.splice(8, 1);
                    setBotInfo(info);

                    let openTotal: number = 0;
                    let symTotal: number = 0;
                    for (let order of botOrders) {
                        // if position is open
                        if (order[7]) {
                            openTotal += Number(order[5]);
                            symTotal += Number(order[2]);
                        }
                    }
                    setOpenAmount(openTotal);
                    setSymOpen(symTotal);
                    console.log('asdafsf')
                    for (let val of Object.values(resp.data.price_history)) {
                        let val_arr = val as string[];
                        setHistPrices(val_arr.map((row: any) => [row[0], row[1]]))
                    }
                    console.log(histPrices)
                    setBotNickname(info[3])
                    //setHistPrices(Object.values(resp.data.price_history))
                });
        }
    }

    const handleHistPrices = () => {
        // todo: this needs to filter out orders that did not exist X days ago
        if (symAmountOpen && openAmount && botInfo) {
            return (
                histPrices.map((hp) => {
                    let histVal: number = symAmountOpen * Number(hp[0])
                    let openVal: number = (symAmountOpen * Number(botInfo[8][0]))
                    let histPct: number = ((openVal - histVal) / openVal) * 100.0
                    let styleDict = (histPct > 0) ? {color: 'green', paddingLeft: '10px'} : {
                        color: 'red',
                        paddingLeft: '10px'
                    }

                    return (
                        <div>{Number(hp[0]).toFixed(2)} - {histVal.toFixed(2)}
                            <span style={styleDict}>
                                ({histPct.toFixed(2)}%) {new Date(hp[1]).toDateString()}
                            </span>
                        </div>
                    )
                })
            )
        }
    }

    const renderBotInfo = (infoArr: string[]) => {
        let openVal: string = '';
        if (symAmountOpen) {
            openVal = (symAmountOpen * Number(infoArr[8][0])).toFixed(2)
        }
        return (
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        strategy:
                    </Grid>
                    <Grid item xs={6}>
                        {infoArr[0]}
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        margin:
                    </Grid>
                    <Grid item xs={6}>
                        ${Number(infoArr[1]).toFixed(2)}
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        subscription:
                    </Grid>
                    <Grid item xs={6}>
                        {infoArr[2]}
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        symbol(s):
                    </Grid>
                    <Grid item xs={6}>
                        {infoArr[4]}
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        Active:
                    </Grid>
                    <Grid item xs={4}>
                        {`${infoArr[5]}`}
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        Exchange:
                    </Grid>
                    <Grid item xs={4}>
                        {infoArr[6]}
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        ModelArtifact:
                    </Grid>
                    <Grid item xs={4}>
                        <a href={""}>{infoArr[7]}</a>
                    </Grid>
                </Grid>
                {openAmount !== null && <div>OPEN: ${openAmount.toFixed(2)}</div>}
                {symAmountOpen !== 0 && <div> OPEN VAL: ${openVal}</div>}
                {openAmount !== null && <div>Profit/Loss: ${(Number(openVal) - openAmount).toFixed(2)}</div>}
                <div>
                    <h3>hist</h3>
                    Price: {Number(infoArr[8][0]).toFixed(2)}
                    {openAmount && handleHistPrices()}
                </div>
            </Container>
        )
    }

    useEffect(() => fetchBot(), [loading]);
    return (
        <AppContainer bgColor={'#FFF8F0'}>
            <h2 style={{textAlign: "center"}}>Bot: {botNickname} </h2>
            {botInfo && <Container>
               <EditBot
                    botId={String(botId)}
                    botName={botInfo[3]}
                    margin={botInfo[1]}
                    subscription={botInfo[2]}
                    symbols={botInfo[4]}
                    active={Boolean(botInfo[5])}
               />
               <Button style={{backgroundColor: "#ea2727", color: "white"}}>
                   Flatten
               </Button>
            </Container>}
            {botInfo && renderBotInfo(botInfo)}
            {orders.length > 0 &&
                <Table
                    rows={orders}
                    columns={['id', 'ext_oid', 'amount', 'status', 'price', 'cost', 'insert_ts', 'open']}
                    columnFilters={['status', 'open']}
                />
            }
        </AppContainer>
    );
}

export default Bot;