import React, {ReactElement, SyntheticEvent, useEffect} from 'react';
import {useState} from 'react';

import Dropdown from './Dropdown';
import {sort} from "d3";

interface TableProps {
    columns: Array<String>
    rows: Array<Array<string | number | boolean | JSX.Element>>
    columnFilters: string[]
}

const Table: React.FC<TableProps> = (props: TableProps) => {
    let row_color_1 = 'lightgray';
    let row_color_2 = 'white';
    let row_color = row_color_1;
    let tdKey: number = 0;
    const defaultRows: Array<Array<string | number | boolean | JSX.Element>> = [];
    const [rows, setRows] = useState(defaultRows);
    const [currentSort, setCurrentSort] = useState<null|string>(null);

    const handleColumnSort = (e: SyntheticEvent) => {
        let column: string = String((e.target as HTMLInputElement).textContent);

        if (currentSort === column){
            //console.log('reverse')
            const out = Array.from(rows.reverse());
            setRows(out);
            return
        }

        setCurrentSort(column);

        const out = props.rows.sort((x,y) => {
            let valX = x[props.columns.indexOf(column)]
            let valY = y[props.columns.indexOf(column)]

            if (React.isValidElement(valX)){
                valX = (valX as JSX.Element);
                valX = valX.props.children;
            }

            if (React.isValidElement(valY)){
                valY = (valY as JSX.Element);
                valY = valY.props.children;
            }
            // console.log(valX, valY, valX > valY)
            if (Number(valX) > Number(valY)){
                return -1
            }
            return 1
        });
        setRows(out);

        // console.log(out)
    }

    const handle_col = (col:string | number | boolean | JSX.Element) => {
        if(typeof col === "boolean") {
            return `${col}`
        } else if (React.isValidElement(col)) {
            return col
        } else if (typeof col === "object"){
            return JSON.stringify(col)
        }
        return col
    }

    function get_row_color() {
        if (row_color === row_color_1) {
            row_color = row_color_2;
            return row_color
        }
        row_color = row_color_1;
        return row_color
    }

    function get_col_options(col: string) {
        let i = props.columns.indexOf(col);
        let opts = new Set(['']);
        if (i >= 0) {
            for (let item in props.rows) {
                if (props.rows[item]) {
                    let opt = handle_col(props.rows[item][i]) as string;
                    opts.add(opt);
                }
            }
            return sort(Array.from(opts))
        }
        return [];
    }

    function changeFilter(evt: React.SyntheticEvent, col_filter: string) {
        let i = props.columns.indexOf(col_filter);
        const filt_rows: Array<Array<string | number | boolean | JSX.Element>> = [];
        for (let item in props.rows) {
            let target = evt.target as HTMLInputElement;
            if (handle_col(props.rows[item][i]) === target.value) {
                filt_rows.push(props.rows[item])
            }
        }
        setRows(filt_rows);
    }

    const filter_dropdowns: React.ReactElement[] = [];
    props.columnFilters.map((cf) => (
        filter_dropdowns.push(
            <Dropdown
                label={cf}
                options={get_col_options(cf.toString())}
                handleChange={(evt) => changeFilter(evt, cf)}/>)
    ))

    let out_rows;

    if (rows.length < 1) {

        out_rows = props.rows.map((row) => (
            <tr key={tdKey++} style={{background: get_row_color()}}>
                {
                    row.map((col) => (
                        <td key={tdKey++}
                            style={{
                            padding: '6px',
                        }}>
                            {handle_col(col)}
                        </td>
                    ))
                }
            </tr>
        ))
                // console.log(out_rows)
    } else {
        out_rows = rows.map((row) => (
            <tr key={tdKey++} style={{background: get_row_color()}}>
                {
                    row.map((col) => (
                        <td key={tdKey++} style={{
                            borderTopWidth: '1px',
                            borderBottomWidth: '1px',
                            borderTopStyle: 'solid',
                            borderColor: 'black',
                            padding: '6px',
                        }}>
                            {handle_col(col)}
                        </td>
                    ))
                }
            </tr>
        ))
        // console.log('out')
        // console.log(out_rows)
    }

    return (
        <div style={{
            overflow: 'auto',
            margin: 'auto',
            background: '#FFF8F0',
            maxHeight: '75vh',
            height: 'inherit'
        }}>
            <div style={{
                display: 'flex',
            }}>
                {filter_dropdowns}
            </div>
            <table style={{
                borderCollapse: 'collapse',
            }}>
                <thead>
                    <tr key={tdKey++}>
                        {props.columns.map((column) => (
                            <th style={{background: 'black', color: 'white', cursor: 'pointer', padding: '10px'}}
                                key={tdKey++}
                                 onClick={(event) => handleColumnSort(event)}
                            >{column}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {out_rows}
                </tbody>
            </table>
        </div>
    )
}

export default Table;