import React from 'react';
import { useState } from 'react';
import axios from 'axios';

import { useAuthContext } from '../util/AuthContext';

import AppContainer from '../components/AppContainer';
import CoinSummary from '../components/CoinSummary';

const Coinbase: React.FC = () => {
  const defaultPerf: any[] = [];
  const [symbolPerformance, setSymbolPerformance] = useState(defaultPerf);
  const [loading, setLoadingStatus] = useState(true);
  const [fetched, setFetched] = useState(false);
  const [fetchSuccess, setFetchSuccess] = useState(true);
  const { hasValidToken, error } = useAuthContext();

  if (!fetched && hasValidToken){
    setFetched(true);
    axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/cnbs/top_of_book`)
    .then((resp) => {
      return resp.data
    })
    .then((data) => {
      const performance: any[] = [];
      for (const key in data['data']){
        const val = data['data'][key];
        const hists = data['price_hist'][key];
        if (hists){
          const bid: number = val.bids[0][0];
          const day_change: number = hists[0][0]
          const week_change: number = hists[1][0]
          const month_change: number = hists[2][0]
          const row = [key, bid, day_change, week_change, month_change];
          performance.push(row)
        } else {
          const row = [key, 0, 0, 0, 0]
          performance.push(row)
        }
      }

      setSymbolPerformance(performance);
      setLoadingStatus(false);
      setFetchSuccess(true);
    })
    .catch((err) => {
      setFetchSuccess(false);
      setLoadingStatus(false);
      throw err
    });
  }

  if (loading){
    return (
      <AppContainer bgColor={'#FFF8F0'}>
        <h1>Loading...</h1>
      </AppContainer>
    );
  }

  if (fetchSuccess){
    return (
      <AppContainer bgColor={'#FFF8F0'}>
        <h1 style={{ textAlign: 'center' }}>Coinbase</h1>
        <div style={{
          margin: '0px auto',
          width: '60%',
          textAlign: 'center',
          borderRadius: '20px',
          background: '#A4B0FF',
         }}>
          <div style={{ fontSize: '20px', borderBottom: 'solid 1px black'}}>
            <div style={{ minWidth: '120px', display: 'inline-block', padding: '5px'}}>Coin</div>
            <div style={{ minWidth: '120px', display: 'inline-block', padding: '5px'}}>Bid</div>
            <div style={{ minWidth: '180px', display: 'inline-block', padding: '5px'}}>24H</div>
            <div style={{ minWidth: '180px', display: 'inline-block', padding: '5px'}}>7D</div>
            <div style={{ minWidth: '180px', display: 'inline-block', padding: '5px'}}>30D</div>
          </div>
          { symbolPerformance.map((row) => (
            <CoinSummary
             name={row[0]}
             bid={row[1]}
             day_change={Number(row[2])}
             week_change={Number(row[3])}
             month_change={Number(row[4])}
           />
        ))}
        </div>
      </AppContainer>
      );
  }
  return (
    <AppContainer bgColor={'#FFF8F0'}>
      {error && <div style={{color: 'red'}}>error: {error.toString()}</div>}
    </AppContainer>
  )

};

export default Coinbase;