import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Profile from './Profile';
import NavBtn from "./NavBtn";
import {
    Toolbar,
    AppBar,
    IconButton,
    Menu,
    MenuItem,
    ListItemText,
    List,
    Collapse
} from "@mui/material"
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import MenuIcon from "@mui/icons-material/Menu"

import logo from '../../logo.svg';

import styles from './appcontainer.module.css';

interface Props {
    bodyBg: string;
    overlayBg: string;
    hasValidAuthToken: boolean;
}

interface NavItem {
    label: string;
    path: string;
    subpaths?: { [key: string]: string };
}

const NavBar: React.FC<Props> = ({ bodyBg, overlayBg, hasValidAuthToken }) => {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [subMenuOpen, setSubMenuOpen] = useState<{ [key: string]: boolean }>({});

    const navItems: NavItem[] = [
        {
          label: 'Portfolio',
          path: '/portfolio'
        },
        {
            label: 'Coinbase',
            path: '/coinbase',
            subpaths: {
                'coinbase': '/coinbase',
                'coinbase pro': '/coinbasepro',
                'coinbase advanced': '/coinbaseadvanced',
                'performance': '/coinbase/performance',
            }
        },
        {
            label: 'Datasets',
            path: '/datasets'
        },
        {
            label: 'Models',
            path: '/models',
            subpaths: {
                'training': '/models',
                'artifacts': '/models/artifacts',
                'evaluate': '/models/eval',
                'evaluation reports': '/models/evals'
            }
        },
        {
            label: 'Sims',
            path: '/sims',
            subpaths: {
                'run sim': '/sims',
                'strategies': '/sims/strats',
                'sim results': '/sims/results',
                'sim explorer': '/sims/explorer',
                'fills': '/fills',
                'opportunity': '/sims/opportunity'
            }
        },
        {
            label: 'Live Feeds',
            path: '/feeds',
        },
        {
            label: 'Bots',
            path: '/bots',
            subpaths: {
                "bots": "/bots",
                "paper trades": "/papertrades"
            }
        },
        {
            label: "Web3",
            path: '/web3',
            subpaths: {
                'home': '/web3',
                'uniswap': 'https://app.uniswap.org',
                'alchemy': 'https://www.alchemy.com',
                'eth foundation': '/ethfoundationrss',
            }
        }
    ];

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuOpen(true);
        setAnchorEl(event.currentTarget);
    }

    const handleCloseMenu = () => {
        setMenuOpen(false);
    }

    const handleClickMenuItem = (path: string, hasSubMenu?: boolean) => {
        if (hasSubMenu) {
            setSubMenuOpen(prevState => ({...prevState, [path]: !prevState[path]}));
        } else {
            window.location.href = path;
            setMenuOpen(false);
        }
    }

    return (
        <AppBar position="static">
            <Toolbar style={{ justifyContent: 'space-between' }}>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={handleOpenMenu}
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    open={menuOpen}
                    anchorEl={anchorEl}
                    onClose={handleCloseMenu}
                >
                    {navItems.map((item) => (
                        <div key={item.label}>
                            {item.subpaths ? (
                                <>
                                    <MenuItem
                                        onClick={() => handleClickMenuItem(item.path, true)}
                                    >
                                        <ListItemText primary={item.label} />
                                        {subMenuOpen[item.path] ? <ExpandLess /> : <ExpandMore />}
                                    </MenuItem>
                                    <Collapse in={subMenuOpen[item.path]} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {Object.entries(item.subpaths).map(([subLabel, subPath]) => (
                                                <MenuItem
                                                    key={subPath}
                                                    onClick={() => handleClickMenuItem(subPath)}
                                                    style={{ paddingLeft: '24px' }}
                                                >
                                                    <ListItemText primary={subLabel} />
                                                </MenuItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                </>
                            ) : (
                                <MenuItem onClick={() => handleClickMenuItem(item.path)}>
                                    <ListItemText primary={item.label} />
                                </MenuItem>
                            )}
                        </div>
                    ))}
                </Menu>
                <img className={styles.logo_navbar_img} src={logo} alt="missing" onClick={() => navigate('/')} />
                <div>
                    <Profile />
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
