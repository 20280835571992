import React, {useEffect, useState} from "react";
import axios from "axios";
import Table from "../Table";
import {type} from "os";
import {CircularProgress} from "@mui/material";

interface DatasetViewerProps {
    datasetId: string
    dateStr: string
    product: string
    exchange: string
}

const DatasetViewer: React.FC<DatasetViewerProps> = ({datasetId, dateStr, product, exchange}) => {
    const [data, setData] = useState<(string|number|boolean|JSX.Element)[][]|null>(null);
    const [columns, setColumns] = useState<string[]>([]);
    const getData = () => {
        setData(null);
        axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/datasets/data`, {params: {
                datasetId: datasetId,
                date: dateStr,
                product: product,
                exchange: exchange
        }}).then((resp) => {
            if (resp.data.data){
                let data = JSON.parse(resp.data.data)
                setData(data.data.map((row: string[]) => row));
                setColumns(data.columns)
            } else {
                setData([]);
                setColumns([]);
            }

        })
    }

    useEffect(() => getData(), [dateStr])

    if (data){
        return (
            <Table columns={columns} rows={data} columnFilters={[]} />
        );
    }

    return (
        <CircularProgress color="secondary"/>
    );

}

export default DatasetViewer;
