import React, {useState, useEffect} from "react";

import axios from "axios";

import AppContainer from "../components/AppContainer";
import LinePlot from "../components/LinePlot";
import {useAuthContext} from "../util/AuthContext";

const CoinbasePerformance: React.FC = () => {
    const { loading, hasValidToken } = useAuthContext();
    const [data, setData] = useState<number[]>([]);
    const [fetched, setFetched] = useState<boolean>(false);

    const getData = () => {
        if (hasValidToken && !fetched){
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/cnbs/performance`)
            .then((respData)=> {
                setData(respData['data']['data'][1]);
                setFetched(true);
            })
        }
    }

    useEffect(() => getData(), [hasValidToken, fetched]);

    const plot = (
        <LinePlot
            data = {{'line': data}}
        />
    );


    return (
        <AppContainer bgColor={'#FFF8F0'} >
            <h1>Performance!</h1>
            {!fetched && (<h2>loading.</h2>)}
            {fetched && plot}
        </AppContainer>
    );
};

export default CoinbasePerformance;