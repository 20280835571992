import React, {useState, useEffect} from "react";
import axios from "axios";
import {useAuthContext} from "../util/AuthContext";

import AppContainer from "../components/AppContainer";
import Table from "../components/Table";

const ModelEvaluations: React.FC = () => {
    const [fetched, setFetched] = useState<boolean>(false);
    const [data, setData] = useState<JSX.Element[][]>([]);
    const { hasValidToken } = useAuthContext();

    const fetchEvals = () => {
        if (hasValidToken){
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/models/evaluations`)
            .then((resp) => {
                let rows: JSX.Element[][] = [];
                for (let i in resp['data']['data']){
                    let plotLink = `/plots/?modelPath=${resp['data']['data'][i]}`
                    let elem = <a href={plotLink}>{resp['data']['data'][i]}</a>;
                    rows.push([elem]);
                }
                setData(rows);
                setFetched(true);
            })
        }
    }

    useEffect(() => fetchEvals(), [hasValidToken, fetched]);

    if (!fetched){
        return (
          <AppContainer bgColor={'#FFF8F0'}>
              <h1>Loading...</h1>
          </AppContainer>
        );
    }

    return (
        <AppContainer bgColor={'#FFF8F0'}>
            <h1>Model Evaluations</h1>
            <Table
              columns={['model_eval']}
              rows={data}
              columnFilters={[]}
            />
        </AppContainer>
    );
};

export default ModelEvaluations;