import React, {ChangeEvent, useState} from "react";
import axios from "axios";
import Dropdown from "../Dropdown";

import styles from "./depositMenu.module.css"

interface DepositMenuProps {
    // called when we hit the close button
    closeMenu: () => void;
}

const DepositMenu: React.FC<DepositMenuProps> = ({closeMenu}) => {
    const [paymentMethods, setPaymentMethods] = useState<null|string[]>(null);
    const [paymentMethod, setPaymentMethod] = useState<null|string>(null);
    const [validForm, setValidForm] = useState<boolean>(false);
    const [depositAmount, setDepositAmount] = useState<number>(0);
    const [confirmDeposit, setConfirmDeposit] = useState<boolean>(false);
    const [finalized, setFinalized] = useState<boolean>(false);
    const [statusMessage, setStatusMessage] = useState<string>('loading');
    const fetchPaymentMethods = () => {
        if (!paymentMethods){
             axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/cnbs/payment_methods`)
            .then((resp) => {
                const methods = [];
                for (let paymentMethod of resp.data.methods) {
                    if (paymentMethod.allow_deposit){
                        methods.push(paymentMethod.name)
                    }
                    console.log(paymentMethod)
                }
                setPaymentMethods(methods);
                if (methods.length > 0){
                    setPaymentMethod(methods[0]);
                }
            })
        }
    }
    fetchPaymentMethods()

    const validateDepositAmount = (e: ChangeEvent) => {
        let amount = (e.target as HTMLInputElement).value;
        let amountVal: number = Number(amount);
        if (amountVal){
            setDepositAmount(amountVal);
            setValidForm(true);
        } else {
            setValidForm(false);
        }
    }

    const submitDeposit = () => {
        setConfirmDeposit(true);
    }


    const finalizeDeposit = () => {
        setFinalized(true);
        axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/cnbs/deposits/usd`, {
            params: {
                amount: depositAmount.toFixed(2),
                paymentMethod: paymentMethod,
            }
        })
        .then((resp) => {
            console.log(resp)
            setStatusMessage('success')
        })
    }

    const depositStatusMessage = () => {
        return <div>{statusMessage}</div>
    }
    const confirmation = () => {
        return <div>
            <h3>Confirm Deposit to {paymentMethod} </h3>
            <div>${depositAmount.toFixed(2)}</div>
            <button disabled={finalized} onClick={() => finalizeDeposit()}>
                Finalize
            </button>
            <button disabled={finalized} onClick={() => {
                setConfirmDeposit(false);
                setValidForm(false);
            }}>
                Cancel
            </button>
            { finalized && depositStatusMessage() }

        </div>
    }

    const handleSetPayment = (e:ChangeEvent) => {
        let payment = (e.target as HTMLSelectElement).value;
        setPaymentMethod(payment);
    }

    return (
        <div className={styles.depositMenuContainer}>

        <div className={styles.depositMenu}>
            <div className={styles.depositMenuHeader}>
                <h4 style={{padding: '10px'}}>Deposit USD</h4>
                <span style={{marginLeft: 'auto', padding: '10px'}}>
                    <button onClick={() => closeMenu()}
                        style={{height: '35px', width: '35px'}}
                    >
                        X
                    </button>
                </span>
            </div>

            {!paymentMethods && <div>Loadin</div>}
            {paymentMethods &&
                <div className={styles.depositMenuBody}>
                    { !confirmDeposit &&
                        <div>
                            <div>
                                <h4>Accounts</h4>
                                <Dropdown label={'Deposit From'}
                                          options={paymentMethods}
                                          handleChange={(e) => handleSetPayment(e)}
                                />
                                <label>Amount USD</label>
                                <input onChange={(e) => validateDepositAmount(e) } />
                            </div>
                            <div className={styles.submitButtonDiv}>
                                <button
                                    disabled={!validForm}
                                    onClick={() => submitDeposit()}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    }
                        { confirmDeposit && confirmation() }
                </div>
            }
        </div>
    </div>
    );
};

export default DepositMenu;