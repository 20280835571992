import react from 'react';

export interface ExchangeConnectionProps {
  oauth_configured: boolean,
  refresh_token: string,
  access_token: string,
  scope: string,
  expiry: string
}

const ExchangeConnection: React.FC<ExchangeConnectionProps> = (ExchangeConnectionProps) => {
  const cnbsOauthUrl:string = `https://www.coinbase.com/oauth/authorize?
response_type=code
&client_id=c56085d1d762dcb06bffe3e637fabdbecbddcfdef1ee8e0eae7c0625aab8ede9
&redirect_uri=http%3A%2F%2F35.164.234.128%3A3901%2Fapi%2Foauth2
&state=SECURE_RANDOM
&scope=wallet:accounts:read
&account=all`;


  if (ExchangeConnectionProps.oauth_configured){
    const expiryDt = new Date(ExchangeConnectionProps.expiry.replace('T', ' ') + ' UTC');
    return (
      <div>
        Configured with oauth: {ExchangeConnectionProps.oauth_configured.toString()}
        <div>
          token: {ExchangeConnectionProps.access_token}
        </div>
        <div>
          scope: {ExchangeConnectionProps.scope}, expiry: {expiryDt.toString()}
        </div>
      </div>
    );
  }

  return (
    <div>

      <a href={cnbsOauthUrl}>Connect with Coinbase</a>
    </div>
  );

};

export default ExchangeConnection;