import React, { FormEvent, useState } from 'react';

import { useAuthContext } from '../../util/AuthContext';

import logo from '../../logo.svg'
import styles from './login.module.css'

const Login: React.FC = () => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const { login, error, loading } = useAuthContext();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    login(user, password)
  }

  const renderError = (e: Error|null) => {
    if (e === null){
      return (<h1>Error</h1>);
    }
    return (<div className={styles.error_message}>Login Failure</div>);
  }

  return (
    <div className={styles.login_container} >
      <form onSubmit={onSubmit}>
        <h3 className={styles.login_header}>
          <img style={{
            height: '75px',
            width: '175px'
          }}
          src={logo} alt='missing'/>
        </h3>
        { error && renderError(error) }
        <input
          className={styles.login_input}
          type="text"
          placeholder="username"
          onChange={(e) => setUser(e.target.value)}
        />
        <input
          className={styles.login_input}
          type="password"
          placeholder="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <input className={styles.login_submit} type="submit" value="Login" />
      </form>
    </div>
  );
};

export default Login;