import React, {useEffect, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Checkbox,
    FormControlLabel
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem'
import {useAuthContext} from "../../util/AuthContext";
import {getCsrfTokenCookie} from "../../util/Misc";
import axios from "axios";

const BotCreator: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [botName, setBotName] = useState('');
  const [strategy, setStrategy] = useState<null|string>(null)
  const [symbol, setSymbol] = useState('')
  const [symbols, setSymbols] = useState([]);
  const [margin, setMargin] = useState(0);
  const [exchange, setExchange] = useState('');
  const [modelArtifacts, setModelArtifacts] = useState([]);
  const [modelArtifact, setModelArtifact] = useState<null|string>(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscription, setSubscription] = useState<null|string>(null);
  const {hasValidToken} = useAuthContext();
  const [isValid, setIsValid] = useState<boolean>(true);

  const fetchModelArtifacts = () => {
      if (hasValidToken) {
          axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/models/artifacts`)
              .then((resp) => {
                  return resp.data
              }).then((data) => {
              setModelArtifacts(data.res.map((row:any) => row[0]))
          })
      }
  }

  const fetchSymbols = () => {
      if (hasValidToken) {
          axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/symbols`)
              .then((resp) => {
                return resp.data
              }).then((data) => {
                setSymbols(data.coins)
              })
      }
  }

  const fetchSubscriptions = () => {
      if (hasValidToken){
          axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/subscriptions`)
              .then((resp) => {
                  return resp.data.subscriptions
              }).then((subs) => {
                  setSubscriptions(subs);
          })
      }
  }

    useEffect(()=>{
        fetchModelArtifacts()
        fetchSymbols()
        fetchSubscriptions()
    }, [hasValidToken])

    const handleSubmit = (e: any) => {
      e.preventDefault()
      axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/bot`,
          {
              botName: botName,
              margin: margin,
              symbol: symbol,
              modelArtifact: modelArtifact,
              subscription: subscription,
              exchange: exchange,
              valid: isValid,
              strategy: strategy
          },
          {
              withCredentials: true,
              headers: {
                  "X-CSRFToken": getCsrfTokenCookie(),
                  "Content-Type": "multipart/form-data"
              }
          }
      ).then((resp) => {
          window.location.reload()
      })

    }

    const handleIsValid = (e:any) => {
      setIsValid(Boolean(e.target.checked));
    }

    return (
        <div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Create Bot
      </Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Create New Bot</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
          <div>
            <TextField
              autoFocus
              margin="dense"
              label="Bot Name"
              type="text"
              value={botName}
              required
              onChange={(val) => setBotName(val.target.value)}
            />
          </div>
          <div>
            <TextField
              margin="dense"
              label="Margin"
              type="text"
              value={margin}
              required
              onChange={(val) => setMargin(Number(val.target.value))}
            />
          </div>
          <div>
            <TextField
              margin="dense"
              label="Symbol"
              select
              required
              sx={{ minWidth: '120px' }}
              value={symbol}
              onChange={(val) => setSymbol(val.target.value)}
            >
                {symbols.map((sym) => <MenuItem value={sym}>{sym}</MenuItem>)}
            </TextField>
          </div>
          <div>
            <TextField
                margin="dense"
                label="Strategy"
                required
                select
                sx={{ minWidth: '120px' }}
                value={strategy}
                onChange={(e) => setStrategy(e.target.value)}
              >
                  <MenuItem value="DollarCostAverage">DollarCostAverage</MenuItem>
            </TextField>
          </div>
          <div>
          <TextField
                margin="dense"
                label="Model Artifact"
                select
                required
                onChange={(v) => setModelArtifact(v.target.value)}
                sx={{ minWidth: '160px' }}
            >
              {modelArtifacts.map((ma) => <MenuItem value={ma}>{ma}</MenuItem>)}
            </TextField>
          </div>
            <TextField
                margin="dense"
                label="Subscription"
                select
                required
                sx={{ minWidth: '130px' }}
                value={subscription}
                onChange={(e) => setSubscription(e.target.value)}
            >
                {subscriptions.map((sub) => <MenuItem value={sub}>{sub}</MenuItem>)}
            </TextField>
          <div>
            <TextField
                value={exchange}
                onChange={(v) => setExchange(v.target.value)}
                label='Exchange'
                margin="dense"
                select
                required
                sx={{ minWidth: '130px' }}
            >
                <MenuItem value={'coinbase_advanced'}>
                    coinbase_advanced
                </MenuItem>
            </TextField>
          </div>
              <div>
                  <FormControlLabel control={<Checkbox value={isValid} onChange={handleIsValid} defaultChecked />} label="Valid" />
              </div>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Create
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
        </div>
    )
}

export default BotCreator;