import React, {useState} from "react";
import AppContainer from "../../components/AppContainer";

import styles from './orderSummary.module.css'
import axios from "axios";
import Table from "../../components/Table";
const OrderSummary: React.FC = () => {
    const [selectedTab, setTab] = useState<string>('');
    const [orders, setOrders] = useState<null|any[][]>(null);
    const [cols, setCols] = useState<string[]>([]);

    const fetchTab = (tabName: string) => {
        if (tabName === 'coinbase advanced') {
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/coinbaseadvanced/orders/all`)
                .then((resp) => {
                    setCols(Object.keys(resp.data.orders[0]));
                    setOrders(Object.values(resp.data.orders).map((order) => Object.values(order as string[])))
                })
        } else if(tabName == 'coinbase') {
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/cnbs/orders/all`)
                .then((resp) => {
                    setCols(Object.keys(resp.data.orders[0]));
                    setOrders(Object.values(resp.data.orders).map((order) => Object.values(order as string[])))
                })
        } else {
            setOrders(null);
        }
    }

    const selectTab = (e: React.MouseEvent) => {
        e.preventDefault();
        let tabName: string = String((e.target as HTMLSpanElement).textContent);
        setTab(tabName);
        fetchTab(tabName);
    }

    const getTabClass = (tabContent: string) => {
        if (selectedTab === tabContent){
            return styles.selectedTab
        }
        return styles.tabSpan;
    }

    return (
        <AppContainer bgColor={'white'}>
            <div>
                <div style={{
                    textAlign: 'center',
                }}>
                    <span className={getTabClass('coinbase advanced')} onClick={(e: React.MouseEvent) => selectTab(e)}>
                        coinbase advanced
                    </span>
                    <span className={getTabClass('coinbase pro')} onClick={(e: React.MouseEvent) => selectTab(e)}>
                        coinbase pro
                    </span>
                    <span className={getTabClass('coinbase')} onClick={(e: React.MouseEvent) => selectTab(e)}>
                        coinbase
                    </span>
                </div>
                {selectedTab && orders &&
                    <Table columns={cols} rows={orders} columnFilters={[]} />
                }

                {
                    <div>
                        <h2>User Order Entry</h2>
                        <div>Exchange</div>
                        <div>Symbol</div>
                        <div>Amount</div>
                        <div>USD?</div>
                    </div>
                }


            </div>
        </AppContainer>
    )
};

export default OrderSummary;