import React from 'react';

interface FooterProps {
    bodyBg: string;
}

const Footer: React.FC<FooterProps> = ({ bodyBg }) => {
    return (
      <div style={{
        background: bodyBg
      }}>
        <div style={{
            minHeight: '100px',
            margin: '0px'
        }}>
            <h3 style={{
                textAlign: 'center'
            }}>
            </h3>
        </div>
      </div>
    );
}

export default Footer;