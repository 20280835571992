import React from 'react';
import { useQueryClient } from 'react-query';

import styles from './datasetsummary.module.css'

interface DatasetSummaryProps {
    name: string,
    total_days: number,
    start_date: Date,
}

const DatasetSummary: React.FC<DatasetSummaryProps> = ({name, total_days, start_date}) => {
  const yy = start_date.getFullYear();
  const mm = start_date.getMonth();
  const dd = start_date.getDate();
//   const queryClient = useQueryClient();

  return (
    <div className={styles.ds_summary_container}>
      <div><a href={`/dataset?datasetId=${name}`}>{ name }</a></div>
      <div>Total Days of data: { total_days }</div>
      <div>Start Date: { start_date.toString() }</div>
      <div>Missing { total_days } days</div>
    </div>
  );
}

export default DatasetSummary;