import React from 'react';
import {useState, useEffect} from 'react';

import axios from 'axios';

import AppContainer from '../components/AppContainer';
import Table from '../components/Table';
import {useAuthContext} from '../util/AuthContext';


const Accounts = () => {

    // const accounts_cb = [{"id": "b9b251c2-c2cd-5472-9263-a2867895abd6", "name": "LRC Wallet", "primary": false, "type": "wallet", "currency": "LRC", "balance": {"amount": "90.58200400", "currency": "LRC"}, "created_at": "2022-04-18T15:09:13Z", "updated_at": "2022-04-18T15:09:17Z", "resource": "account", "resource_path": "/v2/accounts/b9b251c2-c2cd-5472-9263-a2867895abd6", "allow_deposits": true, "allow_withdrawals": true, "native_balance": {"amount": "85.01", "currency": "USD"}}];
    const [accounts, setAccounts] = useState([{
        'name': '',
        'currency': '',
        'balance': {'amount': ''},
        'native_balance': {'amount': ''}
    }]);
    const [usdValue, setUsdValue] = useState<number>(0);
    const [totalCost, setTotalCost] = useState<null|number>(null);
    const [totalValue, setTotalValue] = useState<null|number>(null);
    const [fetched, setFetchSuccess] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const {hasValidToken} = useAuthContext();
    const defaultRows: Array<Array<string | number>> = [];
    const [rows, setRows] = useState(defaultRows);

    function get_accounts() {
        if (hasValidToken && !fetched) {
            setFetchSuccess(true);
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/cnbs/accounts`)
                .then(response => {
                    return response
                })
                .then(data => {
                    let total_balance: number = 0;
                    let newRows = [];
                    data['data']['data'].map((account: any) => {
                        if (account.name === 'USD Wallet'){
                            setUsdValue(account.balance.amount);
                        }
                        if (Number(account.balance.amount) > 0) {
                            newRows.push([account.name, Number(account.balance.amount).toFixed(2),
                                Number(account.native_balance.amount).toFixed(2), account.currency])
                            total_balance += Number(account.native_balance.amount)
                        }
                    });
                    newRows.push(['total', total_balance.toFixed(2), total_balance.toFixed(2), 'USD Value']);
                    setTotalValue(total_balance)
                    newRows.sort((row1, row2) => {
                        return Number(row2[2]) - Number(row1[2])
                    })
                    setRows(newRows)
                    setLoading(false);
                    setTotalCost(data['data']['total_cost'])
                })
        }
    }

    useEffect(() => {
        get_accounts()
    })

    const errorHeader = (
        <h1>Error!!!</h1>
    );

    const acctTable = (
        <div>
            <h1 style={{textAlign: 'center'}}> Accounts </h1>
            <Table columns={['name', 'amount', 'balance', 'currency']} rows={rows} columnFilters={[]}/>
        </div>);

    return (
        <AppContainer bgColor={'#FFF8F0'}>
            {loading && (<h1>Loading...</h1>)}
            {!loading && <h2>USD: $ {usdValue}</h2>}
            {totalValue !== null && <h2>Total Value: ${totalValue.toFixed(2)}</h2>}
            {totalCost !== null && <h2>Total spent: ${totalCost}</h2>}
            {(totalCost !== null && totalValue !== null)
                && <h2>G/L: {(totalValue / totalCost * 100.0).toFixed(2)}% (+{(totalValue - totalCost).toFixed(2)})</h2>
            }
            {hasValidToken && !loading && acctTable}
            {!hasValidToken && errorHeader}
        </AppContainer>
    );
};

export default Accounts;