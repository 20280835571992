import React, { useState, useEffect } from 'react';
import axios from 'axios';

import AppContainer from '../components/AppContainer';
import DatasetSummary from '../components/DatasetSummary';
import { useAuthContext } from '../util/AuthContext';

const Datasets: React.FC = () => {
    const [fetched, setFetched] = useState<boolean>(false);
    const datasetData: any[] = [];
    const [datasetInfo, setDatasetInfo] = useState(datasetData);
    const { hasValidToken } = useAuthContext();

    function getDsInfo(){
      if (!hasValidToken){
          return
      } else if (!fetched){
        const vals: any[] = [];
        setFetched(true);
        axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/datasets/existing_dts`).then((resp) => {
          if (Object.keys(resp.data).length > 0){
            for (const key in resp.data){
              vals.push({'name': key, 'dates': resp.data[key]})
            }
          }
          setDatasetInfo(vals);
        })
      }
    }


    useEffect(() => {
      getDsInfo();
    }, [hasValidToken])


    return (
      <AppContainer bgColor={'#FFF8F0'}>
      <h1>Datasets</h1>
        { hasValidToken && datasetInfo.map((dsInfo) => (
          <DatasetSummary
            name={dsInfo.name}
            total_days={dsInfo.dates.length}
            start_date={ new Date(dsInfo.dates[0] + ' (Central Standard Time)') }
          />
        ))}
      </AppContainer>
    )
};

export default Datasets;
