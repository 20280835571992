import React, {useEffect, useState} from "react";

import AppContainer from "../components/AppContainer";
import CoinbaseAdvancedMenu from "../components/coinbaseAdvancedMenu";
import {useAuthContext} from "../util/AuthContext";
import Table from "../components/Table";
import axios from "axios";

const CoinbaseAdvanced: React.FC = () => {
    const {loading} = useAuthContext();
    const [accounts, setAccounts] = useState<null|string[][]>(null);
    const [orders, setOrders] = useState<null|string[][]>(null);


    const colHeaders = [
        'ID',
        'Name',
        'Currency',
        'Amount',
        'default',
        'active',
        'created_at',
        'updated_at',
        'deleted_at',
        'type',
        'ready',
        'hold',
        'price',
        'native_balance'
    ]

    const orderColumns = [
        'order_id',
        'product',
        'user_id',
        'order_conf',
        'side',
        'client_oid',
        'status',
        'time_in_force',
        'created',
        'complete %',
        'filled size',
        'average fill price',
        'fee',
        'number of fills',
        'filled value',
        'pending cancel',
        'size in quote',
        'total fees',
        'size inclusive of fees',
        'total value after fees',
        'trigger_status',
        'order type',
        'reject reason',
        'settled',
        'product type',
        'reject message',
        'cancel message'
    ]

    const fetchAccounts = () => {
        if (!loading && accounts === null){
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/coinbaseadvanced`)
            .then((resp) => {
                const accts: string[][] = [];
                for (let acct of resp.data.accounts){
                    const acctArr: string[] = Object.values(acct);
                    for(let i in acctArr){
                        if (typeof(acctArr[i]) === 'object' && acctArr[i]){
                            acctArr[i] = Object.values(acctArr[i])[0].toString();
                        }
                    }
                    accts.push(acctArr);
                }
                setAccounts(accts);
            })
        }
    }

    const fetchOrders = () => {
        if (!loading && orders === null){
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/coinbaseadvanced/orders/open`)
            .then((resp) => {
                setOrders(resp.data.open_orders.map((o: Map<string, string>) => Object.values(o) ));
            })
        }
    }

    useEffect(() => {
        fetchAccounts()
        fetchOrders()
    }, [loading, accounts, orders]);



    return (
        <AppContainer bgColor={'#FFF8F0'}>
            <CoinbaseAdvancedMenu/>
            { accounts &&
                <div style={{height: '200px', display: 'unset'}}>
                    <h3 style={{textAlign: 'center'}}>Accounts</h3>
                        <Table columns={colHeaders}
                               rows={accounts}
                               columnFilters={['Currency']} />
                </div>
            }

            { orders && orders.length > 0 && (
                <div>
                    <h3 style={{textAlign: 'center'}}>Open Orders</h3>
                    <Table
                        columns={orderColumns}
                        rows={orders}
                        columnFilters={[]}
                    />
                </div>
            )}
            { orders && orders.length == 0 && (
                <h3 style={{textAlign: 'center'}}>No open orders</h3>
            )}
        </AppContainer>
    );
}

export default CoinbaseAdvanced;
