import React, { useEffect, useState } from "react";
import axios from "axios";

import { useLocation } from "react-router-dom";
import AppContainer from "../components/AppContainer";
import {useAuthContext} from "../util/AuthContext";
import Table from "../components/Table";
import Scatter from "../components/plots/Scatter";

const Fills: React.FC = () => {
    const urlSearch = useLocation().search;
    const urlParams = new URLSearchParams(urlSearch);
    const fillPath = urlParams.get('fillPath');
    const { hasValidToken } = useAuthContext();

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);

    const [plotData, setPlotData] = useState<{[key: string]: [number, number][]}>({});
    const [priceLine, setPriceLine] = useState<[number, number][]>([]);

    const [fetched, setFetched] = useState<boolean>(false);

    const getData = () => {
        if (hasValidToken){
          axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/sim/detail?path=${fillPath}`)
          .then((resp) => {
              let respData = JSON.parse(resp['data']['data']);
              setColumns(respData.columns);
              setRows(respData.data);
              let priceIndex = respData.columns.indexOf('fill_price');
              let fillTypeIndex = respData.columns.indexOf('fill_type');
              let tsIndex = respData.columns.indexOf('fill_timestamp');
              let buys: [number, number][] = [];
              let sells: [number, number][] = [];
              let priceLine: [number, number][] = [];
              for (let i in respData.data){
                  if (respData.data[i][fillTypeIndex] === 'buy'){
                    buys.push([respData.data[i][tsIndex], respData.data[i][priceIndex]]);
                  } else {
                    sells.push([respData.data[i][tsIndex], respData.data[i][priceIndex]]);
                  }
                  priceLine.push([respData.data[i][tsIndex], respData.data[i][priceIndex]]);
              }
              setPlotData({'buys': buys, 'sells': sells});
              setPriceLine(priceLine);
              setFetched(true);
          });
        }
    }

    useEffect(() => getData(), [hasValidToken, setFetched]);

    return (
      <AppContainer bgColor={'#FFF8F0'}>
          <h1>Fills</h1>

          { fetched && (
              <div>
                <Scatter data={plotData} line={priceLine}/>
                <Table columns={columns} rows={rows} columnFilters={[]}/>
              </div>
          )}
      </AppContainer>
    );
};

export default Fills;