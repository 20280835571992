import React, {useEffect, useState} from 'react';
import AppContainer from "../components/AppContainer";
import Table from "../components/Table";
import {Container} from "@mui/material";
import axios from "axios";
import {useAuthContext} from "../util/AuthContext";


const Portfolio: React.FC = () => {
    const {hasValidToken} = useAuthContext();
    const [data, setData] = useState<any>([]);
    const getPortfolioDetails = () => {
        console.log("portfolio")
        if (hasValidToken){
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/cnbs/performance/v2`).then((resp)=> {
                return JSON.parse(resp.data.result)
            }).then((result) => setData(result))
        }
    }

    useEffect(() => getPortfolioDetails(), [hasValidToken])

    return (
        <AppContainer bgColor={"#FFF8F0"}>
            <Container>Portfolio</Container>
            <Table columns={['sym', 'amount', 'price', 'value', 'percent']} rows={data} columnFilters={[]} />
        </AppContainer>
    );
}

export default Portfolio;
