import React from "react";
import { useState, useEffect } from 'react';
import axios from 'axios';

import AppContainer from "../components/AppContainer";
import Table from "../components/Table";
import {useAuthContext} from "../util/AuthContext";

const CoinbasePro: React.FC = () => {
    const {loading, hasValidToken} = useAuthContext();
    const [showAccounts, setShowAccounts] = useState<boolean>(false);
    const [paymentMethods, setPaymentMethods] = useState<string[]|null>(null);

    const [accounts, setAccounts] = useState([]);
    const [fetched, setFetched] = useState(false);

    const getAccts = () => {
        if (hasValidToken && !fetched){
            setFetched(true)
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/coinbasepro`)
                .then((resp) => {
                    setAccounts(resp.data.accts);
                })
        }
    }

    const getPaymentMethods = () => {
        if (paymentMethods == null){
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/coinbasepro/paymentmethods`)
                .then((resp)=> {
                    let names = [];
                    for (let pm in resp.data.methods){
                        names.push(resp.data.methods[pm]["name"]);
                    }
                    setPaymentMethods(names);
                })
            return <div></div>
        } else {
            return paymentMethods.map((method) => <div>Method: {method}</div>)
        }
    }
    getAccts()
    return (
         <AppContainer bgColor={'#FFF8F0'} >
             <h3 style={{'color': 'red'}}>Advanced Trade API will be replacing Coinbase Pro APIs in early 2023 once all API customers are migrated!!!!</h3>
             { fetched && (<Table
                columns={['id', 'currency', 'balance', 'hold', 'available', 'profile', '_']}
                rows={accounts}
                columnFilters={[]}
            />)
                 }
             <button onClick={ () => setShowAccounts(!showAccounts) }>Deposit From Bank Account</button>
             { showAccounts && <div style={{'borderStyle': 'solid', 'borderWidth': '1px', 'borderColor':'black'}}>
                 {getPaymentMethods()}</div> }
        </AppContainer>
    );
};

export default CoinbasePro;