import React, { useState, FormEvent } from 'react';
import { useAuthContext } from '../../util/AuthContext';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import styles from './appcontainer.module.css';

import profileImg from '../../static/profile.png';


const Profile: React.FC = () => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const { logout } = useAuthContext();

  const clickLogout = (e: FormEvent) => {
    e.preventDefault();
    logout();
  }

  const hoverMenu = () => {
    setShowProfileMenu(true);
  }

  const closeMenu = () => {
    setShowProfileMenu(false);
  }

  const profile_menu = (
    <div
      className={styles.profile_div}
      onMouseEnter={() => hoverMenu()}
      onMouseLeave={() => closeMenu()}
    >
      <div className={styles.profile_menu_div}>
        <div className={styles.menu_item}>
          <Link className={styles.menu_item_link} to="/settings/oauth">
            Oauth Settings
          </Link>
        </div>
        <div className={styles.menu_item}>
          <Link className={styles.menu_item_link} to="/accounts">
            Accounts
          </Link>
        </div>
        <div className={styles.menu_item}>
          <Link className={styles.menu_item_link} to="/orders/all">
            Orders
          </Link>
        </div>
        <div className={styles.menu_item}>
          <a className={styles.menu_item_link} href="/airflow/home">
            airflow
          </a>
        </div>
        <div className={styles.menu_item}>
          <a className={styles.menu_item_link} href="http://cryptotrader.local:5555">
            flower
          </a>
        </div>
        <div className={styles.menu_item}>
          <a className={styles.menu_item_link} href={"/test"}>
            test
          </a>
        </div>
          <div className={styles.menu_item}>
          <a className={styles.menu_item_link} href={"/testEchart"}>
            test Echart
          </a>
        </div>
        <div className={styles.menu_item} onClick={clickLogout}>
          Logout
        </div>
      </div>
    </div>
  );


  return (
      <li key='profileLi' className={ styles.navbar_profile_li }>
        <div
          onMouseEnter={() => hoverMenu()}
          onMouseLeave={() => closeMenu()}
        >
          <AccountCircleIcon />
        {showProfileMenu && profile_menu}
        </div>
      </li>
  );
};

export default Profile;