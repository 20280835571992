import React, { useState, useEffect } from 'react';
import axios from 'axios';

import AppContainer from '../components/AppContainer';
import ExchangeConnection, { ExchangeConnectionProps } from '../components/ExchangeConnection';
import { useAuthContext } from '../util/AuthContext';

const CoinbaseConnection: React.FC = () => {
  const { hasValidToken } = useAuthContext();
  const defaultCxn: ExchangeConnectionProps = {'oauth_configured': false, 'refresh_token': '', 'access_token': '',
                                               'scope': '', 'expiry': ''};
  const [connectionData, setConnectionData] = useState(defaultCxn);
  const [fetching, setFetching] = useState(true);

  const getConfig = () => {
    if (hasValidToken){
      axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/cnbs/oauth_settings`)
      .then((resp) => {
      setFetching(false);
      setConnectionData(resp['data'])
    })
    }
  }

  useEffect(() => getConfig(), [hasValidToken]);

  return (
    <AppContainer bgColor={ '#FFF8F0' }>
      <h3>Coinbase Connection Oauth Connection Settings</h3>
      <div style={{'margin': '10px'}}>
        { hasValidToken && !fetching && <ExchangeConnection {...connectionData} /> }
      </div>

      <h3>Coinbase Advanced Oauth Connection Settings</h3>
    </AppContainer>
  )
};

export default CoinbaseConnection;