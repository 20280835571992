import React, {useState, useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import AppContainer from "../components/AppContainer";
import {useAuthContext} from "../util/AuthContext";

import axios from "axios";
import DatasetViewer from "../components/DatasetViewer";

const Dataset: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { hasValidToken } = useAuthContext();
    const datasetId = searchParams.get('datasetId');
    const [datasetDetails, setDetails] = useState<null|Record<string, (string|string[])>>(null);
    const [currentDt, setCurrentDt] = useState<string|null>(null);

    useEffect(() => {
        if (hasValidToken){
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/datasets/dataset?name=${datasetId}`)
            .then((resp) => {
                console.log(resp)
                setDetails(resp.data)
                setCurrentDt(resp.data.existing_dates[0][0] as string)
            })
                .catch((err) => {
                    throw err
                })
        }
    }, [hasValidToken])

    const handleSelectDt = (e:any) => {
        setCurrentDt(e.target.value);
    }

    return (
        <AppContainer bgColor={'#2787EA'}>
            <h3>Dataset {datasetId} </h3>
            {datasetDetails && (<div>
                {datasetDetails.dataset_id}
                <div>
                    <select onChange={handleSelectDt}>
                        {(datasetDetails.existing_dates as string[]).map((r) => <option>{r}</option>)}
                    </select>
                    <table>
                        <thead>
                            <th>Feature</th>
                        </thead>
                        <tbody>
                            {(datasetDetails.features as string[]).map((r) => <tr><td>{r}</td></tr>)}
                        </tbody>
                    </table>

                    <h3>Dataset Viewer - {currentDt}</h3>
                    <div>
                        {currentDt &&
                            <DatasetViewer
                                datasetId={datasetDetails.dataset_id as string}
                                exchange={'cbadv'}
                                product={'BTC-USD'}
                                dateStr={currentDt}
                            />}
                    </div>
                </div>
            </div> )}

        </AppContainer>
    );
}

export default Dataset;