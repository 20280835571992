import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from 'axios';

import AppContainer from "../components/AppContainer";
import LinePlot from "../components/LinePlot";
import { useAuthContext } from "../util/AuthContext";
import Table from "../components/Table";

const Plot: React.FC = () => {

    const { hasValidToken } = useAuthContext();
    const urlSearch = useLocation().search;
    const urlParams = new URLSearchParams(urlSearch);
    const modelPath = urlParams.get('modelPath');

    const [data, setData] = useState<{[key: string]: number[]}>({});
    const [error, setError] = useState<boolean>(false);
    const [fetched, setFetched] = useState<boolean>(false);
    const [errorValue, setErrorValue] = useState<number|string>('');

    useEffect(() => {
        if (hasValidToken && !fetched){
             axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/models/evaluation/results?path=${modelPath}`)
            .then((resp)=>{
                let respData = {
                    'resp': resp['data']['real'],
                    'preds': resp['data']['preds'],
                }

                setData(respData);
                setErrorValue(resp['data']['MSE'])
                setFetched(true);
            });
        }

    }, [hasValidToken, fetched]);

    if (error){
        return (
            <AppContainer bgColor={'#FFF8F0'}>
                <h1>Error</h1>
            </AppContainer>
        );
    }

    if (fetched){
        let vals: number[][] = [];
        for (let i in data.resp){
            let index = Number(i)
            vals.push([data.resp[index], data.preds[index]]);
        }
        return (
            <AppContainer bgColor={'#FFF8F0'}>
                <LinePlot
                    data={data}
                />
                <h2> Error: {errorValue}</h2>
                <Table
                    columns={['real', 'preds']}
                    rows={vals}
                    columnFilters={[]}
                />
            </AppContainer>
        );
    }

    return (
        <AppContainer bgColor={'#FFF8F0'}>
            <div>loading...</div>
        </AppContainer>
    );

};

export default Plot;