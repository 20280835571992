import React, {useEffect, useState} from "react";
import AppContainer from "./AppContainer";
import axios from 'axios';
import Table from '../components/Table';
import {useAuthContext} from "../util/AuthContext";

const PaperTrades: React.FC = () => {
    const [summaryData, setSummary] = useState<any[]|undefined>();
    const {loading} = useAuthContext();
    const fetchDetails = () => {
        if (!loading){
             axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/papertrade`).then((resp) => {
               console.log("FETCH DEETS")
               console.log(resp)
               setSummary(resp.data.data)
            })
        }
    }

    useEffect(() => fetchDetails(), [loading])

    return (
        <AppContainer bgColor={'#FFF8F0'}>
            <div>Paper trades!</div>
            <div>
                {summaryData &&
                    <Table
                        columns={['ID', 'SYM', 'SPENT', 'AMOUNT', 'CURRENT PRICE', 'VALUE', '%']}
                        rows={summaryData}
                        columnFilters={[]}
                    />}
            </div>
        </AppContainer>
    )
}

export default PaperTrades;
