import React from 'react';

import styles from "./dropdown.module.css"

interface DropdownProps {
    label: string;
    options: string[];
    handleChange: (values: any) => void;
}

const Dropdown: React.FC<DropdownProps> = ({label, options, handleChange}) => {

    return (
        <div className={styles.dropdownDiv}>
            <label key={`${label}LabelKey`}>{label}: </label>
            <select key={`${label}Key`}
                    className={styles.dropdownSelect}
                    name='filter'
                    id='filters'
                    onChange={(event) => (handleChange(event))}>
                {
                    options.map((opt) => (
                        <option key={opt}>{opt}</option>
                    ))
                }
            </select>
        </div>
    );
};

export default Dropdown;