import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './index.css';

import App from './App';
import Auth from './util/Auth'

import OauthSettings from "./routes/oauthSettings";

import Orders from './routes/orders';
import Home from './routes/home';
import Coinbase from './routes/coinbase';
import CoinbaseConnection from './routes/coinbaseConnection';
import CoinbasePerformance from "./routes/coinbasePerformance";
import CoinbasePro from "./routes/coinbasepro";
import CoinbaseAdvanced from "./routes/coinbaseAdvanced";

import Accounts from './routes/accounts';

import Datasets from './routes/datasets';
import Dataset from "./routes/dataset";

import Models from './routes/models';
import ModelArtifacts from "./routes/modelArtifacts";
import ModelEval from "./routes/modelEval";
import ModelEvaluations from "./routes/modelEvaluations";

import Sims from './routes/sims';
import SimResults from "./routes/simResults";
import SimExplorer from "./routes/simExplorer";
import Plot from "./routes/plot";

import Test from "./routes/test";
import TestEcharts from "./routes/testEcharts";

import Fills from "./routes/fills";

import Bots from "./routes/bots";
import Bot from "./routes/bot";

import reportWebVitals from './reportWebVitals';
import OrderSummary from "./routes/orderSummary";
import Strategies from "./routes/strategies";
import Feeds from "./routes/feeds";
import OpportunityReport from "./routes/opportunityReport";

import Portfolio from "./routes/portfolio";

import EthFoundationRss from "./routes/ethFoundationRss";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { RainbowKitProvider, getDefaultWallets } from '@rainbow-me/rainbowkit'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { polygon } from 'wagmi/chains'
import { publicProvider } from 'wagmi/providers/public'
import '@rainbow-me/rainbowkit/styles.css'

import PaperTrades from "./components/paperTrades";

const queryClient = new QueryClient();

const { chains, publicClient } = configureChains(
  [polygon],
  [publicProvider()] // we can attach alchemy provider if necessary
)
const { connectors } = getDefaultWallets({
  // change to actual values
  appName:
    process.env.NEXT_PUBLIC_RAINBOWKIT_POJECT_NAME ?? 'RainbowWalletConnect',
  projectId: process.env.NEXT_PUBLIC_RAINBOWKIT_POJECT_ID ?? 'c6d945caeec00a57b103a446edcb4e75',
  chains,
})
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
})

ReactDOM.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
    <RainbowKitProvider showRecentTransactions={true} chains={chains}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Auth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Routes>
            <Route path="/" element={ <App /> }>
              <Route index element={ <Home /> }/>

              <Route path="settings/oauth" element={<OauthSettings/>} />

              <Route path="orders" element={<Orders />} />
              <Route path="orders/all" element={<OrderSummary />} />

              <Route path="coinbase" element={<Coinbase />} />
              <Route path="coinbase/connect" element={<CoinbaseConnection />} />
              <Route path="coinbase/performance" element={<CoinbasePerformance />} />
              <Route path="coinbasepro" element={<CoinbasePro />} />
              <Route path="coinbaseadvanced" element={<CoinbaseAdvanced />} />

              <Route path="accounts" element={<Accounts />} />
              <Route path="datasets" element={<Datasets />} />
              <Route path="dataset" element={<Dataset />} />

              <Route path="models" element={<Models />} />
              <Route path="models/artifacts" element={<ModelArtifacts/> } />
              <Route path="models/eval" element={<ModelEval /> } />
              <Route path="models/evals" element={<ModelEvaluations />} />

              <Route path="sims" element={<Sims />} />
              <Route path="sims/results" element={<SimResults />} />
              <Route path="sims/explorer" element={<SimExplorer />} />
              <Route path="sims/opportunity" element={<OpportunityReport />} />
              <Route path="sims/strats" element={<Strategies />} />

              <Route path="feeds" element={<Feeds />} />

              <Route path="plots" element={<Plot />} />

              <Route path="bots" element={<Bots />} />
              <Route path="bot/:botId" element={<Bot />} />
              <Route path="papertrades" element={<PaperTrades />} />

              <Route path="test" element={<Test />} />
              <Route path="testEchart" element={<TestEcharts />} />
              <Route path="fills" element={<Fills />} />
              <Route path="portfolio" element={<Portfolio />} />
              <Route path="ethfoundationrss" element={<EthFoundationRss />} />

              <Route path="*" element={
                  <main style={{ padding: "1rem" }}>
                   <p>There's nothing here!</p>
                  </main>
                }
              />
            </Route>
          </Routes>
          </LocalizationProvider>
        </Auth>
      </QueryClientProvider>
      </BrowserRouter>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
