import React, {useEffect} from 'react';
import {useState} from "react";
import {Link} from "react-router-dom";
import {useAuthContext} from "../util/AuthContext";

import AppContainer from "../components/AppContainer";
import axios from "axios";

const OauthSettings: React.FC = () => {
    const {loading} = useAuthContext();
    const [userOauthSettings, setUserOauthSettings] = useState<null|string[]>(null);
    const [apps, setApps] = useState<Map<string, number>>(new Map());
    const [showSetup, setShowSetup] = useState<boolean>(false);
    const [supportedApps, setSupportedApps] = useState<null|string[][]|Map<string,string>[][]>(null);
    const [selectedApp, setSelectedApp] = useState<string>('');


    let selectedAppId: string = '-1';
    let selectedAppScopes: Map<string, string> = new Map();

    for (var i in supportedApps){
        let index: number = Number(i);
        if (supportedApps[index][1] === selectedApp){
            selectedAppId = supportedApps[index][0] as string;
            selectedAppScopes = supportedApps[index][5] as Map<string,string>;
        }
    }


    const fetchSettings = () => {
        if (!loading && userOauthSettings === null){
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/user/oauth2_settings`)
            .then((resp)=>{
                setUserOauthSettings(resp.data.oauth_settings);
            })
        }
    }

    const fetchApps = () => {
        if (!loading){
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/oauth2/applications`)
            .then((resp) => {
                for (var app in resp.data.oauth_apps){
                    apps.set(resp.data.oauth_apps[app][1], resp.data.oauth_apps[app][2]);
                }
                setSupportedApps(resp.data.oauth_apps);
            })
        }
    }

    const selectApp = (e: React.MouseEvent) => {
        let elem:HTMLSpanElement = (e.target as HTMLSpanElement)
        setSelectedApp((elem.textContent as string));
    }

    const connectionInfo = () => {
        let out = <div>
            { Array.from(Object.keys(selectedAppScopes)).map((scope) =>
                <div>
                  <input type={"checkbox"} />
                  <label>{scope}</label>
                </div>
            )}

                <a href={`${process.env.REACT_APP_BASE_API_URL}/api/oauth2/redirect_endpoint/${selectedAppId.toString()}`} >
                    connect to {selectedApp}
                </a>
            </div>;
        for (var i in userOauthSettings){
            let index: number = Number(i);
            if (userOauthSettings && userOauthSettings[index][0] === selectedAppId){
                out = <div><div>{userOauthSettings[index][1].toString()} is already registered</div>
                        <a href={`${process.env.REACT_APP_BASE_API_URL}/api/oauth2/redirect_endpoint/${selectedAppId.toString()}`} >
                            reconnect</a></div>;
            }
        }
        return out
    }

    const oauthSetupWindow = () => {
        console.log(supportedApps)
        if (showSetup){
            return (
                <div style={{
                    'height': '100vh',
                    'width': '100vw',
                    'background': 'rgb(0, 0, 0, .5)',
                    'position': 'absolute',
                    'top': '0px'
                }}>
                    <div
                        style={{
                        'background': '#2787EA',
                        'margin': '20vh',
                        'minHeight': '45vh',
                      }}
                    >
                        <Link to=''
                              onClick={() => setShowSetup(false)}
                              style={{
                                  textDecoration: 'none',
                                  color: 'black',
                                  position: 'relative',
                                  fontSize: '20px',
                                  height: '25px',
                                  width: '25px',
                                  display: 'block',
                                  top: '0px',
                                  left: '0px',
                                  textAlign: 'center'
                              }}
                        >
                            X
                        </Link>
                        <h2 style={{'textAlign': 'center'}}>Oauth Client Application Setup</h2>
                        <div style={{
                            'display': 'flex',
                            'justifyContent': 'center',
                        }}>
                            {supportedApps && supportedApps.map((sa) => (
                                <span style={{
                                    'padding': '5px',
                                    'marginRight': '5px',
                                    'borderStyle': 'solid',
                                    'borderWidth': '1px',
                                    'borderRadius': '7.5%',
                                    'cursor': 'pointer',
                                    'color': sa[1] === selectedApp ? 'red': 'green'
                                }}
                                className={'appSelect'}
                                onClick={(e) => selectApp(e)}
                                >
                                    {sa[1]}
                                </span>
                            ))}
                        </div>
                        { selectedApp !== '' &&
                            (
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: '10px'
                                }}>
                                    <h3>Description</h3>
                                    <div>{selectedApp} is a great exchange</div>
                                    {connectionInfo()}
                                </div>
                            )
                        }
                    </div>
                </div>
            );
        }
    }

    useEffect(()=> {
        fetchSettings()
        fetchApps()
    }, [loading, userOauthSettings, selectedApp]);

    let settingsDiv =
        <div>
            <h3>current oauth application settings</h3>
            <div style={{padding: '5px'}}>
                {
                    userOauthSettings?.map((arr) => {
                      return <div>{arr[1]} scope: {arr[2]} expiry: {(new Date(arr[3])).toString()}</div>
                    })
                }
            </div>
        </div>;


    return (
        <AppContainer bgColor={'gray'}>
            { userOauthSettings && userOauthSettings.length === 0 &&
              <h2>You have not added any oauth2 applications yet</h2>
            }
            { userOauthSettings && userOauthSettings.length > 0 &&  settingsDiv}
            <div>
                <button onClick={() => setShowSetup(!showSetup)}>
                    Add Oauth App
                </button>
                { oauthSetupWindow() }
            </div>
        </AppContainer>
    );
}

export default OauthSettings;
