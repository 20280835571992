import AppContainer from "../components/AppContainer";
import {useAuthContext} from "../util/AuthContext";
import {useEffect, useState} from "react";
import axios from "axios";

const Strategies = () => {
    const [strats, setStrats] = useState<string[]>([]);
    const { hasValidToken } = useAuthContext();

    const getStrats = () => {
        if (hasValidToken){
            axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/strats/supported`)
                .then((resp) => {
                    console.log(resp.data);
                    setStrats(resp.data.strats);
                })
        }
    }

    useEffect(() => {
        getStrats()
    }, [hasValidToken]);

    return (
        <AppContainer bgColor={'#FFF8F0'}>
            <h2>Strats</h2>
            <div>
                <ul>
                    {strats.map((s) => <li>{s}</li>)}
                </ul>
            </div>
        </AppContainer>
    )
}

export default Strategies;
